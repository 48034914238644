import React from 'react';
import {getStringDate} from '../../services/Common';

const Reward =  ({reward}) => {

  return(
    <div className="reward">
      <p className="selection">
        Sélection<span className="d-block">{reward.selection}</span>
      </p>
      <div className="content">
        <p className="name">
          <span>{reward.name}</span>
          <span className="city">{reward.city}</span>
        </p>
        <p className="date">
          {getStringDate(new Date(reward.date), false, true)}
        </p>
      </div>
      <p className="more">
        {reward.more}
      </p>
    </div>
  );
  
};

export default Reward;