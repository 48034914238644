import React, { Component } from 'react';
import autobind from 'react-autobind';
import Cover from '../../components/cover/Cover';

import {getRecruitmentDatas, getQuestionsDatas, getOrderedQuestions, getOrderedJobsResumes} from '../../services/Recruitment';
import Question from '../../components/recruitment/Question';
import JobPoster from '../../components/recruitment/JobPoster';

import {initAOS} from '../../services/Common';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class Recrutement extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      datas    : getRecruitmentDatas(),
      questions: getQuestionsDatas()
    };
  }

  componentDidMount(){
    initAOS(AOS);
  }

  componentWillReceiveProps (){ 
    AOS.refresh(); 
  }

  render() {

    const questions = getOrderedQuestions().map((question, index) => {
      return(
        <Question question={question} key={index} />
      );
    });

    const openedJobsResumes = getOrderedJobsResumes(true).map((job, index) => {
      return(
        <JobPoster job={job} key={index} />
      );
    });

    const closedJobsResumes = getOrderedJobsResumes(false).map((job, index) => {
      return(
        <JobPoster job={job} key={index} />
      );
    });

    return (
      <div>
        <Cover message={this.state.datas.title} name={this.state.datas.name}/>
        <div className="container position-relative">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-12">
              <p className="text-center">
                {this.state.datas.description}
              </p>
            </div>
            <div className="col-lg-12 text-center mt-5">
              <span className="alert alert-secondary" role="alert">
                {this.state.datas.email.description} <a href="mailto:recrutement@creativecommunity.fr" >{this.state.datas.email.address}</a>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {openedJobsResumes.length > 0 ? <h2 className="pt-5 pb-2 text-center" data-aos="fade-up">Postes ouverts</h2> : ''}
              <div className="row justify-content-center">
                {openedJobsResumes}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {closedJobsResumes.length > 0 ? <h2 className="pt-4 pb-2 text-center" data-aos="fade-up">Postes fermés</h2> : ''}
              <div className="row justify-content-center">
                {closedJobsResumes}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-justify">
              <h2 className="text-center mt-4 mb-2" data-aos="fade-up">{this.state.questions.title}</h2>
              {questions}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
