import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Workshop =  ({workshop, index}) => {

  const features = workshop.features.map((feature, index) => {
    return (
      <p key={index} className="feature">
        <FontAwesomeIcon icon={feature.icon}/>
        <span>{feature.value}</span>
      </p>
    );
  });

  const offset = index % 2 === 0 ? 'offset-lg-2' : '';

  const clickOnForm = (e) => {
    document.getElementById('module_'+e.currentTarget.dataset.module).checked = true;
    const element = document.getElementById('form');
    window.scroll({
      behavior: 'smooth',
      left    : 0,
      top     : element.offsetTop - 130
    });
  };

  return (
    <div className={'col-lg-4 col-md-6 col-sm-12 mb-4 ' + offset} key={index}>
      <div className={'card h-100 workshop colored-border top ' + workshop.color}>
        <div className="card-header text-center">
          <p className="h5 card-title mt-3">{workshop.name}</p>
        </div>
        <div className="card-body">
          <p className="card-text text-justify">{workshop.desc}</p>
          <div>
            {features}
          </div>
          <span className="btn btn-dark w-100" data-module={workshop.id} onClick={clickOnForm}>Contactez-nous</span>
        </div>
      </div>
    </div>
  );
};

export default Workshop;