import React, { Component } from 'react';
import autobind from 'react-autobind';
import Cover from '../../components/cover/Cover';
import {getJobById, getJobCommonMessage} from '../../services/Recruitment';
import JobPoster from '../../components/recruitment/JobPoster';
import {initAOS} from '../../services/Common';
import ReactHtmlParser from 'react-html-parser';
import ArticleElement from '../../components/common/ArticleElement';
import VideoGallery from '../../components/common/VideoGallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AOS from 'aos';
import 'aos/dist/aos.css';

export default class Job extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job          : getJobById(props.match.params.jobId),
      commonMessage: getJobCommonMessage(),
      key          : Math.random()
    };
    autobind(this);
  }

  componentDidMount(){
    initAOS(AOS);
  }

  componentWillReceiveProps (nextProps){
    AOS.refresh();
    this.setState({
      job: getJobById(nextProps.match.params.jobId),
      key: Math.random()
    });
    window.location.reload();
  }

  render() {
    const skills = this.state.job.skills.map((skill, index) => {
      return(
        <li key={index}>{skill}</li>
      );
    });

    const needs = this.state.job.needs.map((need, index) => {
      return(
        <li key={index}>{need}</li>
      );
    });

    const documents = this.state.job.documents.map((doc, index) => {
      return(
        <li key={index}>{doc}</li>
      );
    });

    const suggestions = this.state.job.suggestions.map((suggestion, index) => {
      const job = getJobById(suggestion);
      return(
        <JobPoster job={job} key={index} />
      );
    });

    const images = this.state.job.images.map((image, index) => {
      const img = require('../../assets/images/recruitment/illustration/' + image + '.jpg');
      return(
        <img src={img} alt={image} key={index} className="img-fluid mb-3 w-100" data-aos="fade-up"/>
      );
    });

    const isImages = images.length > 0;

    return (
      <div key={this.state.key}>
        <Cover message={this.state.job.coverMessage} name={this.state.job.id} jobOpen={this.state.job.open}/>
        <div className="container">
          <div className="row">
            <div className="col-12" data-aos="fade-up">
              <p className="text-justify font-weight-bold">{this.state.job.description}</p>
            </div>
            <div className="col-12" data-aos="fade-up">
              <p className="text-justify">{ReactHtmlParser(this.state.job.details)}</p>
            </div>
          </div>

          <hr className="my-4" data-aos="fade-up"/>

          <div className="row" data-aos="fade-up">
            <div className="col-12">
              {this.state.job.videos.length > 0 ? <VideoGallery videos={this.state.job.videos} className="mt-4"/> : ''}
            </div>
          </div>

          <div className="row mt-4">
            <div className= {isImages ? 'col-lg-6 col-md-12 d-flex flex-column' : 'col-lg-12 col-md-12 d-flex flex-column'}>
              <div className={'jumbotron pt-4 mb-0 colored-border left ' + this.state.job.color} data-aos="fade-up">
                <div className="container">
                  <div className="row">
                    <div className={isImages ? 'col-2 d-flex align-items-center' : 'col-1 d-flex align-items-center'}>
                      <span className="h4 text-muted">1.</span>
                    </div>
                    <div className={isImages ? 'col-10' : 'col-11'}>
                      <p className="h4 text-md-left">Ce qui est nécessaire pour nous rejoindre</p>
                    </div>
                  </div>
                  <hr className="my-4"/>
                  <ul className="text-justify">
                    {needs}
                    {skills}
                  </ul>
                </div>
              </div>

              <div className="text-center mb-2 mt-2">
                <FontAwesomeIcon icon={['fas', 'angle-down']}/>
              </div>

              <div className={'jumbotron pt-4 mb-0 colored-border left ' + this.state.job.color} data-aos="fade-up">
                <div className="container">
                  <div className="row">
                    <div className={isImages ? 'col-2 d-flex align-items-center' : 'col-1 d-flex align-items-center'}>
                      <span className="h4 text-muted">2.</span>
                    </div>
                    <div className={isImages ? 'col-10' : 'col-11'}>
                      <p className="h4 text-md-left">Documents à fournir</p>
                    </div>
                  </div>
                  <hr className="my-4"/>
                  <ul className="text-justify">
                    {documents}
                  </ul>
                </div>
              </div>

              <div className="text-center mb-2 mt-2">
                <FontAwesomeIcon icon={['fas', 'angle-down']}/>
              </div>

              <div className={'jumbotron pt-4 mb-0 colored-border left ' + this.state.job.color} data-aos="fade-up">
                <div className="container d-flex flex-column h-100">
                  <div className="row">
                    <div className={isImages ? 'col-2 d-flex align-items-center' : 'col-1 d-flex align-items-center'}>
                      <span className="h4 text-muted">3.</span>
                    </div>
                    <div className={isImages ? 'col-10' : 'col-11'}>
                      <p className="h4 text-md-left">Rejoignez-nous !</p>
                    </div>
                  </div>

                  <hr className="my-4 w-100"/>

                  <div className="h6 text-center d-flex align-items-center flex-grow-1">
                    <span>Envoyez vos candidatures à l'adresse suivante : <a href="mailto:recrutement@creativecommunity.fr" >recrutement@creativecommunity.fr</a></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 m-mt-3">
              <div className="row">
                <div className="col-12">
                  {images.length > 0 ? images : ''}
                </div>
              </div>
            </div>
          </div>

          <ArticleElement title={this.state.commonMessage.title} description={this.state.commonMessage.text} imagePath={this.state.commonMessage.image} directionToTheLeft={this.state.commonMessage.toTheLeft}/>

          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="text-center pt-5 pb-2" data-aos="fade-up">Voir d'autres postes</h2>
            </div>
            {suggestions}
          </div>
        </div>
      </div>
    );
  }
}
