import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const ArticleElement = ({title, description, imagePath, directionToTheLeft}) => {
  const image = require('../../assets/images/'+imagePath);
  
  return (
    <div className="row" data-aos="fade-up">
      <div className="col-12">
        <h2 className="text-center pt-5 pb-2">{title}</h2>
      </div>
      {
        directionToTheLeft==='true' ? 
          <div className="col-lg-6 col-sm-12 mb-4">
            <img className="img-fluid" alt={image} src={image}/>
          </div>
          : ''
      }
      <div className="col-lg-6 col-sm-12 d-flex align-items-center text-justify"> 
        {ReactHtmlParser(description)}
      </div>
      {
        directionToTheLeft==='false' ? 
          <div className="col-lg-6 col-sm-12 mb-4">
            <img className="img-fluid" alt={image} src={image}/>
          </div>
          : ''
      }
    </div> 
  );
};

export default ArticleElement;