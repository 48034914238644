import React, { Component } from 'react';
import autobind from 'react-autobind';
import ImgsViewer from 'react-images-viewer';
import {setGridScale} from '../../services/Common';

export default class contactForm extends Component {

  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      imgs         : props.imgs,
      isOpen       : false,
      galleryFolder: props.galleryFolder,
      organized    : props.organized,
      currImg      : 0,
    };
  }

  openImgsViewer (index, event) {
    event.preventDefault();
    this.setState({
      currImg: index,
      isOpen : true,
    });
  }

  closeImgsViewer () {
    this.setState({
      currImg: 0,
      isOpen : false,
    });
  }

  gotoPrev () {
    this.setState({
      currImg: this.state.currImg - 1
    });
  }

  gotoNext () {
    this.setState({
      currImg: this.state.currImg + 1
    });
  }

  gotoImg (index) {
    this.setState({
      currImg: index
    });
  }

  handleClickImg () {
    if (this.state.currImg === this.props.imgs.length - 1) return;
    this.gotoNext();
  }

  renderGallery () {
    const gallery = this.state.imgs.map((obj, index) => {
      const img = require('../../assets/images/'+this.state.galleryFolder+'/gallery/' + obj.name);
      if(this.state.organized){
        const gridScale = setGridScale(this.state.imgs.length, 6);
        return (
          <div className={('mb-3 col-md-' + gridScale)} key={index}>
            <a href={img} key={index} onClick={(e) => this.openImgsViewer(index, e)} className="d-block w-100 h-100">
              <img src={img} alt={obj.alt} title={obj.credit} className="img-responsive d-block w-100 h-100 object-fit-cover"/>
            </a>
          </div>
        );
      } else {
        return (
          <a href={img} key={index} onClick={(e) => this.openImgsViewer(index, e)} className="thumbnail">
            <img src={img} alt={obj.alt} title={obj.credit} />
          </a>
        );
      }
    });

    return (
      <div className="gallery row">
        {gallery}
      </div>
    );
  }

  buildImgs(){
    const content = this.props.imgs.map(e => {
      const img = require('../../assets/images/'+this.state.galleryFolder+'/gallery/'+e.name);
      const obj = {
        src    : img, 
        caption: e.credit, 
        alt    : e.alt
      };
      return obj;
    });
    return content;
  }

  render() {
    return (
      <div className="w-100">
        {this.renderGallery()}
        <ImgsViewer
          backdropCloseable
          currImg={this.state.currImg}
          imgs={this.buildImgs()}
          isOpen={this.state.isOpen}
          onClickImg={this.handleClickImg}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrev}
          onClickThumbnail={this.gotoImg}
          onClose={this.closeImgsViewer}
          showThumbnails={this.props.showThumbnails}
        />
      </div>
    );
  }
}