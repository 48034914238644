import React, { Component } from 'react';
import autobind from 'react-autobind';
import {Link} from 'react-router-dom';

export default class PresentationModule extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      title       : props.title,
      presentation: props.presentation,
      links       : props.links
    };
  }

  render() {

    const links = this.state.links.map((link, index) => {
      return (
        <div className="col-lg-3 col-sm-12" key={index}>
          <Link to={link.url} className="btn btn-dark">{link.title}</Link>
        </div>
      );
    });
    return (
      <div className="presentation-module">
        <div className="presentation">
          <div className="container" data-aos="fade-left">
            <div className="row">
              <div className="col-lg-6 col-md-12 d-flex align-items-center justify-content-center">
                <h3>{this.state.title}</h3>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="jumbotron">
                  <p className="text-justify">{this.state.presentation}</p>
                  <p className="text-center">
                    <Link to="/studio/presentation" className="btn btn-dark">En savoir plus</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-links">
          <div className="container" data-aos="fade-right">
            <div className="band-inline row">
              <div className="col-lg-3 col-sm-12">
                <p>Vous voulez en savoir plus sur nous ?</p>
              </div>
              {links}
            </div>
          </div>
        </div>
      </div>
    );
  }
}