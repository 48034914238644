import React from 'react';

const Helper =  ({helper, index}) => {
  return (
    <div className="col-lg-6 col-sm-12" key={index}>
      <div className="row">
        <div className="col-6 text-right font-weight-bold">{helper.pseudo}</div>
        <div className="col-6 text-left">{helper.role}</div>
      </div>
    </div>
  );
};

export default Helper;