import React from 'react';
import Cover from '../../components/cover/Cover';
import { getLegalNoticesDatas } from '../../services/LegalNotices';
import { useTranslation } from 'react-i18next';

const LegalNotices = () => {
  const { hosting, reproduction, documents } = getLegalNoticesDatas();
  const { t } = useTranslation('legalNotice');

  const logoCreaCom = require('../../assets/images/'+reproduction.image.path);

  const hosts = hosting.host.map((host, index) => {
    const logo = require('../../assets/images/'+host.image.path);
    return (
      <div>
        <a href={host.link}><img src={logo} alt={host.image.title} width="150px" key={index} className="mr-4"/></a>
        <p className="mt-2">{host.place.host}<br/>{host.place.adress},<br/>{host.place.postalCode} {host.place.city}, {host.place.country}</p>
      </div>
    );
  });

  const filesLegalNotices = documents.docs.map((doc, index) => {
    const file = require('../../assets/files/'+doc.path);
    return (
      <li key={index}>
        <a href={file}>{doc.title}</a> Mis à jour le {doc.date}
      </li>
    );
  });


  return (
    <div>
      <Cover message="Mentions légales" name="legal_notices"/>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="mt-4">{t('reproductionTitle')}</h2>
            <p className="conte text-justify">
              {t('reproductionDescription')}
            </p>
            <div className="bg-transparent">
              <img src={logoCreaCom} alt={reproduction.image.title} />
            </div>

            <h2 className="mt-5">{t('associationInformationsTitle')}</h2>
            <p>
              {t('associationInformationsName')}<br/>
              {t('associationInformationsAddress')}<br/>
              {t('associationInformationsCity')}<br/>
              {t('associationInformationsCountry')}
            </p>

            <h2 className="mt-5">{t('hostingTitle')}</h2>
            {hosts}
            <h2 className="mt-5">{t('publicationTitle')}</h2>
            <ul>
              <li>
                {t('publicationDirectorName')}
              </li>
            </ul>

            <h2 className="mt-5">{t('documentTitle')}</h2>
            <ul>
              {filesLegalNotices}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalNotices;