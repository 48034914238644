import React, { Component } from 'react';
import Routes from '../../routes';
import {BrowserRouter as Router} from 'react-router-dom';
import autobind from 'react-autobind';
import MyNavbar from './Navbar';
import MyFooter from './Footer';
import ScrollToTop from '../utils/ScrollToTop';
import '../../assets/css/init.css';
import { addBackToTop } from 'vanilla-back-to-top'
import { CookiesProvider } from 'react-cookie';
import CookiesBanner from './CookiesBanner';


/**
 * Used for FontAwesome icons
 */
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fab, fas, far);


export default class App extends Component {
  constructor(props) {
    require('dotenv').config();
    super(props);
    autobind(this);
    addBackToTop();
  }

  componentDidMount() {
    if (!document.documentElement.classList.contains('wf-active')) {
      import('webfontloader').then(WebFont =>
        WebFont.load({
          custom: {
            families: ['HeitiTC'],
            urls: ['/fonts.css']
          },
          google: {
            families: ['Roboto:400,700']
          }
        })
      );
    }
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          <div className="pb-4" >
            <CookiesProvider>
              <CookiesBanner>
                <MyNavbar />
                <Routes />
                <MyFooter />
              </CookiesBanner>
            </CookiesProvider>
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}
