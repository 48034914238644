import React, { Component } from 'react';
import autobind from 'react-autobind';
import {Route, Switch} from 'react-router-dom';
import Home from './containers/pages/Home';
import Projects from './containers/pages/Projects';
import Team from './containers/pages/Team';
import Partners from './containers/pages/Partners';
import Presentation from './containers/pages/Presentation';
import PageNotFound from './containers/pages/PageNotFound';
import ProjectPage from './containers/pages/ProjectPage';
import LegalNotices from './containers/pages/LegalNotices';
import Contact from './containers/pages/Contact';
import Recruitement from './containers/pages/Recruitment';
import Translation from './containers/pages/Translation';
import Job from './containers/pages/Job';
import Workshop from './containers/pages/Workshop';
import Press from './containers/pages/Press';
import Doublage from './containers/pages/Doublage';
import {getStringAuthorizedPages} from './services/Common';
import {getJobsResumes} from './api/Recruitment';
import {getProjectsResumes} from './api/Projects';
import ReactGA from 'react-ga';

import AOS from 'aos';
import 'aos/dist/aos.css';

/**
 * Routing Component
 */

export default class Router extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount(){
    AOS.init({
      disable : 'mobile',
      duration: 500,
      once    : true,
      offset  : 80
    });
  }

  componentWillReceiveProps (){ 
    AOS.refresh(); 
  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Implement authorizedProjectPage with all projects to restrict the acces to the project route
    const projects = getProjectsResumes();
    const authorizedProjectPages = getStringAuthorizedPages(projects);

    const jobs = getJobsResumes();
    const authorizedJobsPages = getStringAuthorizedPages(jobs);

    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home}/>
        <Route exact path="/projets" component={Projects}/>
        <Route exact path={'/projets/:projectId('+authorizedProjectPages+')'} component={ProjectPage}/>
        <Route exact path="/studio/equipe" component={Team}/>
        <Route exact path="/studio/presentation" component={Presentation}/>
        <Route exact path="/studio/partenaires" component={Partners}/>
        <Route exact path="/mentions-legales" component={LegalNotices}/>
        <Route exact path="/contact" component={Contact}/>
        <Route exact path="/recrutement" component={Recruitement}/>
        <Route exact path={'/recrutement/:jobId('+authorizedJobsPages+')'} component={Job}/>
        <Route exact path="/services/traduction" component={Translation}/>
        <Route exact path="/services/ateliers" component={Workshop}/>
        <Route exact path="/presse/" component={Press}/>
        <Route path="" component={PageNotFound} />
      </Switch>
    );
  }
}