import {getDatas, getQuestions, getJobsResumes, getOneJobById, getCommonMessage} from '../api/Recruitment';

export const getRecruitmentDatas = () => {
  return getDatas();
};

export const getQuestionsDatas = () => {
  return getQuestions();
};

export const getOrderedQuestions = () => {
  return getQuestions().questions.sort(function (a, b){
    return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0;
  });
};

export const getOrderedJobsResumes = (opened) => {
  return getJobsListByOpenFilter(getJobsResumes(), opened).sort(function (a, b){
    return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0;
  });
};

const getJobsListByOpenFilter = (jobs, opened) => {
  return jobs.filter(job => {
    if(opened){
      return job.open;
    } else {
      return !job.open;
    }
  });
};

export const getJobById = (jobId) => {
  return getOneJobById(jobId);
};

export const getJobCommonMessage = () => {
  return getCommonMessage();
};