import React from 'react';
import {hasSocialNetwork} from '../../services/Member';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getSocialLink = ({username, name}) => {
  switch(name){
  case 'twitter':
    return `https://www.twitter.com/${username}`;
  case 'facebook':
    return `https://www.facebook.com/${username}`;
  case 'website':
    return username;
  case 'instagram':
    return `https://www.instagram.com/${username}`;
  case 'youtube':
    return `https://www.youtube.com/${username}`;
  case 'deviantart':
    return `https://${username}.deviantart.com/`;
  case 'vimeo':
    return `https://www.vimeo.com/${username}`;
  case 'soundcloud':
    return `https://soundcloud.com/${username}`;
  case 'linkedin':
    return `https://www.linkedin.com/in/${username}/`;
  case 'behance':
    return `https://www.behance.net/${username}`;
  case 'imdb':
    return `https://www.imdb.com/name/${username}`;
  case 'tumblr':
    return `https://${username}.tumblr.com`;
  case 'artstation':
    return `https://artstation.com/${username}`;
  default:
    return '';
  }
};

const MemberSocialNetwork =  ({member, pointBefore}) => {
  if(hasSocialNetwork(member)){
    return(
      <span className={pointBefore ? 'rs small text-dark point-before' : 'rs small text-dark'}>
        {member.socials && member.socials.map((social, index) => {
          return (
            <a href={getSocialLink(social)} className={`ml-1 ${social.name}`} key={index}>
              <FontAwesomeIcon icon={[social.name === 'website' ? 'fas' : 'fab', social.icon]}/>
            </a>);
        })}
      </span>
    );
  }
  return('');
};

export default MemberSocialNetwork;