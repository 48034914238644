import React, { Component } from 'react';
import autobind from 'react-autobind';
import Cover from '../../components/cover/Cover';
import {getTranslationDatas, getSubtitlesModuleDatas, getExampleModuleDatas, getWorkModuleDatas, getCounterpartsDatas} from '../../services/Translation';
import ReactHtmlParser from 'react-html-parser';
import ContactFormContainer from '../../components/contact/ContactFormContainer';
import WorkModule from '../../components/common/WorkModule';
import ArticleElementBlank from '../../components/common/ArticleElementBlank';

import {setGridScale, initAOS} from '../../services/Common';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class Translation extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      datas       : getTranslationDatas(),
      subtitles   : getSubtitlesModuleDatas(),
      example     : getExampleModuleDatas(),
      counterparts: getCounterpartsDatas(),
      work        : getWorkModuleDatas()
    };
  }

  componentDidMount(){
    initAOS(AOS); 
  }

  componentWillReceiveProps (){ 
    AOS.refresh(); 
  }

  render() {
    const works = this.state.work.works.map((work, index) => {
      return (
        <div className={'col-lg-' + setGridScale(this.state.work.works.length, 6) + ' col-sm-12 mb-4'} data-aos="fade-up" key={index}>
          <WorkModule work={work} index={index} />
        </div>
      );
    });
    
    return (
      <div>
        <Cover message={this.state.datas.title} name={this.state.datas.name} />
        <div className="container">
          <div className="row">
            <div className="col-12 text-justify" data-aos="fade-up"> 
              {ReactHtmlParser(this.state.datas.description)}
            </div>
          </div>
 
          <ArticleElementBlank title={this.state.subtitles.title} description={this.state.subtitles.description} imagePath={this.state.subtitles.image} directionToTheLeft={this.state.subtitles.toTheLeft} color={this.state.subtitles.color}/>  
          
          {/*<div className="row">
            <div className="col-12" data-aos="fade-up">
              <h2 className="text-center mt-5 mb-4">{this.state.example.title}</h2>
            </div>
            <div className="col-12 mb-4" data-aos="fade-up">
              <Video video={this.state.example.video} />
            </div>
            {/* images 
          </div>*/}

          <div className="row justify-content-center">
            <div className="col-12" data-aos="fade-up">
              {works.length > 0 ? <h2 className="text-center mt-5 mb-4">{this.state.work.title}</h2> : ''}
            </div>
            {works}
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <h2 className="text-center mt-5 mb-4">{this.state.counterparts.title}</h2>
            </div>

            <div className="col-12 text-justify mb-4">
              {ReactHtmlParser(this.state.counterparts.description)}
            </div>
          </div>

          <h2 className="text-center mt-5 mb-4" data-aos="fade-up">Parlez-nous de votre projet</h2>
          <ContactFormContainer formName="TranslationForm" requestType="traduction" formColor="teal"/>
        </div>
      </div>
    );
  }
}
