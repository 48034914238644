import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/app/App';
import * as Sentry from '@sentry/browser';
import './i18n';

if(process.env.NODE_ENV === 'production'){
  Sentry.init({dsn: process.env.REACT_APP_SENTRY_KEY});
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
