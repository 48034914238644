import React from 'react';

const FilePoster =  ({e, k}) => {
  const image = require('../../assets/images/press/pressKit/'+e.id+'.jpg');
  const file = require('../../assets/files/pressKit/'+e.id+'.pdf');

  return (
    <div className="col-lg-4 col-md-6 col-sm-12 project-poster small-height" key={k}>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className="d-inline-block">
          <a href={file} className="d-inline-block position-relative">
            <img src={image} alt={e.title} className="img-fluid"/>
            <span className="position-absolute d-flex justify-content-center align-items-center h-100">
              <span>{e.description}</span>
            </span>
          </a>
          <p className="pt-2 pb-2 m-0 text-center">
            {e.name}      
          </p>
        </div>
      </div>
    </div> 
  );
};

export default FilePoster;