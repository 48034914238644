import React from 'react';
import YouTube from 'react-youtube';

const Video = ({video, className}) => {

  const opts = {
    height    : '',
    width     : '',
    playerVars: video.playerVars
  };

  let containerClassName = 'embed-responsive embed-responsive-16by9';
  if(className){
    containerClassName += ' ' + className;
  }
  return (
    <YouTube videoId={video.id} opts={opts} className="embed-responsive-item" containerClassName={containerClassName}/>
  );
};

export default Video;