import React, { Component } from 'react';
import autobind from 'react-autobind';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

export default class contactForm extends Component {

  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      showIndex                  : false,
      showBullets                : false,
      infinite                   : true,
      showThumbnails             : true,
      showFullscreenButton       : false,
      showGalleryFullscreenButton: false,
      showPlayButton             : false,
      showGalleryPlayButton      : true,
      showNav                    : true,
      isRTL                      : false,
      slideDuration              : 450,
      slideInterval              : 5000,
      thumbnailPosition          : 'bottom',
      showVideo                  : {},
      autoPlay                   : false,
      videos                     : this.buildContent()
    };
  }

  buildContent(){
    const content = this.props.videos.map(v => {
      const original = require('../../assets/images/' + v.original);
      const thumbnail = require('../../assets/images/' + v.thumbsnail);
      const obj = {
        thumbnail  : thumbnail,
        original   : original,
        embedUrl   : 'https://www.youtube.com/embed/' + v.videoId + '?autoplay=1&showinfo=0&rel=0',
        description: v.description,
        renderItem : this.renderVideo.bind(this)
      };
      return obj;
    });
    return content;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.slideInterval !== prevState.slideInterval ||
        this.state.slideDuration !== prevState.slideDuration) {
      // refresh setInterval
      this._imageGallery.pause();
      this._imageGallery.play();
    }
  }

  onSlide() {
    this.resetVideo();
  }

  handleInputChange(state, event) {
    this.setState({[state]: event.target.value});
  }

  handleCheckboxChange(state, event) {
    this.setState({[state]: event.target.checked});
  }

  handleThumbnailPositionChange(event) {
    this.setState({thumbnailPosition: event.target.value});
  }

  resetVideo() {
    this.setState({showVideo: {}});

    if (this.state.showPlayButton) {
      this.setState({showGalleryPlayButton: true});
    }

    if (this.state.showFullscreenButton) {
      this.setState({showGalleryFullscreenButton: true});
    }
  }

  toggleShowVideo(url) {
    // eslint-disable-next-line
    this.state.showVideo[url] = !(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({showGalleryPlayButton: false});
      }

      if (this.state.showFullscreenButton) {
        this.setState({showGalleryFullscreenButton: false});
      }
    }
  }

  renderVideo(item) {
    return (
      <div className="image-gallery-image">
        {
          this.state.showVideo[item.embedUrl] ?
            <div className="video-wrapper embed-responsive embed-responsive-16by9">
              {/* eslint-disable-next-line */}
              <a className="close-video" onClick={this.toggleShowVideo.bind(this, item.embedUrl)}> </a>
              <iframe
                src={item.embedUrl}
                frameBorder="0"
                allowFullScreen
                className="embed-responsive-item"
                title={item.description}
              >
              </iframe>
            </div>
            :
            // eslint-disable-next-line
            <a onClick={this.toggleShowVideo.bind(this, item.embedUrl)}>
              <div className="play-button"></div>
              <img src={item.original} alt={item.description}/>
              {
                item.description &&
                  <span className="image-gallery-description" style={{right: '0', left: 'initial'}}>
                    {item.description}
                  </span>
              }
            </a>
        }
      </div>
    );
  }

  render() {
    return (
      <div className={this.props.className}>
        <ImageGallery
          ref={i => this._imageGallery = i}
          items={this.state.videos}
          lazyLoad={false}
          autoPlay={this.state.autoPlay}
          infinite={this.state.infinite}
          onSlide={this.onSlide.bind(this)}
          showBullets={this.state.showBullets}
          showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
          showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
          showThumbnails={this.state.showThumbnails}
          showIndex={this.state.showIndex}
          showNav={this.state.showNav}
          isRTL={this.state.isRTL}
          thumbnailPosition={this.state.thumbnailPosition}
          slideDuration={parseInt(this.state.slideDuration)}
          slideInterval={parseInt(this.state.slideInterval)}
          additionalClass="app-image-gallery"
        />
      </div>
    );
  }
}