import {getPartners} from '../api/Partners';

export const getPartnersDatas = () => {
  return getPartners();
};

export const getOrderedPartners = () => {
  return getPartners().partners.sort(function (a, b){
    return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0;
  });
};