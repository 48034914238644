import {getProjectsResumes, getProject} from '../api/Projects';

export const getOrderedProjectsResumes = () => {
  return getProjectsResumes().sort(function (a, b){
    return new Date(b.release) - new Date(a.release);
  });
};

export const getProjectsResumesNumbers = (numberOfProjects) => {
  const projects = getOrderedProjectsResumes();
  return projects.slice(0, numberOfProjects);
};

export const getProjectDetail = (projectId) => {
  const project = getProject(projectId);
  project.credits = orderCredits(project.credits);
  project.rewards = orderRewards(project.rewards);
  project.modules = orderModules(project.modules);
  return project;
};

const orderCredits = (credits) => {
  credits.team = credits.team.sort(function (a, b){
    return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0;
  });
  credits.cast = credits.cast.sort(function (a, b){
    return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0;
  });
  return credits;
};

const orderRewards = (rewards) => {
  rewards.sort(function (a, b){
    return new Date(b.date) - new Date(a.date);
  });
  return rewards;
};

const orderModules = (modules) => {
  modules = modules.sort(function (a, b){
    return (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0;
  });
  return modules;
};