import React from 'react';
import {
  Card,
  CardBody,
  CardText
} from 'reactstrap';
import MemberSocialNetworks from './MemberSocialNetworks';

const ExternalActor =  ({actor, index}) => {
  return (
    <div className="col-lg-3 col-md-6 pb-4" key={index}>
      <Card className="external-actor h-100" data-aos="fade-up">
        <CardBody>
          <h5 className="card-title text-center">
            {actor.pseudo}
          </h5>
          <CardText className="text-center">
            <MemberSocialNetworks member={actor} />
            {actor.description}
          </CardText>
        </CardBody>
      </Card>
    </div>
  );
};

export default ExternalActor;