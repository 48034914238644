import React from 'react';
import ProjectCover from './ProjectModuleCover';
import ProjectVideoModule from '../common/ProjectVideoModule';
import ProjectTextModule from '../common/ProjectTextModule';
import ProjectSoundModule from '../common/ProjectSoundModule';
import {getGenericString} from '../../services/Common';

const ProjectModule =  ({module}) => {

  const moduleContent = () => {
    switch(module.type.toLowerCase()){
    case 'videogallery' :
      return <ProjectVideoModule module={module} />;
    case 'text' :
      return <ProjectTextModule module={module}/>;
    case 'sound' :
      return <ProjectSoundModule module={module} />;
    default:
      return '';
    }
  };

  const cover = <ProjectCover message={module.cover.name} imageName={module.cover.image} textColor={module.cover.color}/>;
  return(
    <div id={getGenericString(module.cover.name)}>
      {cover}
      <div className="container" data-aos="fade-up">
        {moduleContent()}
      </div>
    </div>
  );
  
};

export default ProjectModule;