import {getDatas, getAimModule, getConclusionModule, getillustrationsModule, getInterventions, getWorkshopsModule} from '../api/Workshop';

export const getWorkshopPageDatas = () => {
  return getDatas();
};

export const getAimModuleDatas = () => {
  return getAimModule();
};

export const getConlusionModuleDatas = () => {
  return getConclusionModule();
};

export const getillustrationsModuleDatas = () => {
  return getillustrationsModule();
};

export const getInterventionsDatas = () => {
  return getInterventions();
};

export const getWorkshopsModuleDatas = () => {
  return getWorkshopsModule();
};