import React, { Component } from 'react';
import {FormGroup, Label, Input} from 'reactstrap';

export default class InputForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name        : props.name,
      labelContent: props.labelContent,
      type        : props.type,
      required    : props.required,
      onChange    : props.onChange
    };
  }

  render() {
    return (
      <FormGroup>
        <Label for={this.state.name}>{this.state.labelContent} {this.state.required ? <span className="text-danger">*</span> : ''}</Label>
        <Input type={this.state.type} name={this.state.name} id={this.state.name} required={this.state.required} onChange={this.state.onChange} />
      </FormGroup>
    );
  }
}