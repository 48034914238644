import React from 'react';
import InputForm from './InputForm';

const DoublageForm = ({onChange}) => {
  return (
    <div>
      <InputForm name="from_name" labelContent="Nom et prénom du porteur de projet" type="text" required={true} onChange={onChange} />
      <InputForm name="from_email" labelContent="Adresse mail du porteur de projet (pour vous recontacter)" type="email" required={true} onChange={onChange} />
      <InputForm name="from_structure" labelContent="Nom de l'organisme ou de la structure" type="text" onChange={onChange} />
      <InputForm name="doublage_detail" labelContent="Présentation détaillée du projet" type="textarea" required={true} onChange={onChange} />
      <InputForm name="doublage_nb" labelContent="Nombre de voix nécessaires (féminines et masculines)" type="text" required={false} onChange={onChange} />
      <InputForm name="message" labelContent="Autres informations éventuelles" type="textarea" onChange={onChange} />
    </div>
  );
};

export default DoublageForm;



