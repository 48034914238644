import React from 'react';
import {Link} from 'react-router-dom';

const JobPoster =  ({job, index}) => {
  const image = require('../../assets/images/recruitment/' + job.id + '.jpg');

  return (
    <div className="col-lg-6 col-md-6 col-sm-12 poster" key={index}>
      <div data-aos="fade-up">
        <Link to={'/recrutement/'+job.id} className="d-inline-block position-relative">
          <img src={image} alt={job.name} className="img-fluid"/>
          <span>
            <span>{job.description}</span>
            <button className="btn btn-dark">En savoir plus</button>
          </span>
        </Link>
        <p className="pt-2 text-center">
          {job.name}
        </p>
      </div>
    </div>
  );
};

export default JobPoster;