import React, { Component } from 'react';
import autobind from 'react-autobind';
import {getHomeDatas} from '../../services/Home';
import VideoModule from '../../components/home/VideoModule';
import ProjectModule from '../../components/home/ProjectModule';
import PresentationModule from '../../components/home/PresentationModule';
import ServicesModule from '../../components/home/ServicesModule';
import RecruitmentModule from '../../components/home/RecruitmentModule';

import AOS from 'aos';
import 'aos/dist/aos.css';
import {initAOS} from '../../services/Common';


export default class Home extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    const datas = getHomeDatas();

    this.state = {
      title             : datas.title,
      videoModule       : datas.videoModule,
      projectsModule    : datas.projectsModule,
      presentationModule: datas.presentationModule,
      servicesModule    : datas.servicesModule,
      recruitmentModule : datas.recruitmentModule
    };
  }

  componentDidMount(){
    initAOS(AOS);
  }

  render() {
    return (
      <div>
        <VideoModule video={this.state.videoModule.video} title={this.state.videoModule.title} banner={this.state.videoModule.banner} />
        <ProjectModule title={this.state.projectsModule.title} />
        <PresentationModule title={this.state.presentationModule.title} presentation={this.state.presentationModule.presentation} links={this.state.presentationModule.links} />
        <ServicesModule services={this.state.servicesModule.services} />
        <RecruitmentModule title={this.state.recruitmentModule.title} titleBtn={this.state.recruitmentModule.title_btn} url={this.state.recruitmentModule.url} />
      </div>
    );
  }
}