import React from 'react';

export default class Cover extends React.Component {
  constructor(props) {
    super(props);

    const textColorAuto = this.props.textColor ? this.props.textColor : 'light';
    this.state = {
      message  : this.props.message,
      image    : this.props.image,
      textColor: textColorAuto,
      name     : this.props.name
    };
  }
  
  render() {
    const image = require('../../assets/images/covers/projects/cover_'+this.state.name+'.jpg');

    return (
      <div className="head project">
        <img src={image} alt="cover" className="img-fluid" />
        <div className={'cover-message text-' + this.state.textColor}>
          <h1 >{this.state.message}</h1>
        </div>
      </div>
    );
  }
}