export const initAOS = (AOS) => {
  AOS.init({
    disable : 'mobile',
    duration: 500,
    once    : true,
    offset  : 80
  });
};

export const getStringDate = (date, displayDay, displayMonthName) => {
  let day = date.getDate();
  let month = date.getMonth()+1;
  const year = date.getFullYear();

  if(displayDay){
    day = day < 10 ? '0' + day : day;
  }
  
  if(displayMonthName){
    switch(month){
    case 1 :
      month = 'janvier';
      break;
    case 2 :
      month = 'février';
      break;
    case 3 :
      month = 'mars';
      break;
    case 4 :
      month = 'avril';
      break;
    case 5 :
      month = 'mai';
      break;
    case 6 :
      month = 'juin';
      break;
    case 7 :
      month = 'juillet';
      break;
    case 8 :
      month = 'aout';
      break;
    case 9 :
      month = 'septembre';
      break;
    case 10 :
      month = 'octobre';
      break;
    case 11 :
      month = 'novembre';
      break;
    case 12 :
      month = 'décembre';
      break;
    default :
      month = '';
    }
  } else {
    month = month < 10 ? '0' + month : month;
  }
  
  let separator = '/';
  if(displayMonthName){
    separator = ' ';
  }

  if(displayDay){
    return day + separator + month + separator + year;
  } else {
    return month + separator + year;
  }
  
};

export const getStringAuthorizedPages = (pages) => {
  let authorizedPages = '';
  pages.forEach(function(page) {
    authorizedPages += page.id;
    if(page.id !== pages[pages.length-1].id){
      authorizedPages += '|';
    }
  });

  return authorizedPages;
};

export const setGridScale = (size, min) => {
  const nb = Math.floor(12 / size);
  if(nb < min){
    return min;
  }
  return nb;
};

export const getGenericString =(str) => {
  return str.split(' ').join('_').normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^\w\s]/gi, '');
};

export const getSecondsFromString = (str) => {
  let h = 0;
  let m = 0;
  let s = 0;

  if(str.split('h').length > 1){
    h = str.split('h')[0];
  }

  m = str.split('m')[0];
  if(m.split('h').length > 1){
    m = m.split('h')[1];
  }
  
  s = str.split('m')[1].split('s')[0];

  h = parseInt(h, 10);
  m = parseInt(m, 10);
  s = parseInt(s, 10);

  m += h * 60;
  s += m * 60;
  return s;
};