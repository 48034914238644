import React, { Component } from 'react';
import autobind from 'react-autobind';
import Cover from '../../components/cover/Cover';
import WorkModule from '../../components/common/WorkModule';
import {setGridScale, initAOS} from '../../services/Common';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Gallery from '../../components/common/Gallery';
import FilePoster from '../../components/common/FilePoster';

import {getPressPageDatas, getGalleryDatas, getPressKitModuleDatas, getWorkModuleDatas} from '../../services/Press';
import {Link} from 'react-router-dom';

export default class Press extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      workPress: getWorkModuleDatas(),
      datas    : getPressPageDatas(),
      pressKit : getPressKitModuleDatas(),
      gallery  : getGalleryDatas()
    };
  }

  componentDidMount(){
    initAOS(AOS); 
  }

  componentWillReceiveProps (){ 
    AOS.refresh(); 
  }

  render() {
    const works = this.state.workPress.works.map((work, index) => {
      return (
        <div className={'col-lg-' + setGridScale(this.state.workPress.works.length, 6) + ' col-sm-12 mb-4'} data-aos="fade-up" key={index}>
          <WorkModule work={work} index={index} />
        </div>
      );
    });
    
    const pressKit = this.state.pressKit.kits.map((kit, index) => {
      return(
        <FilePoster e={kit} k={index} key={index}></FilePoster>
      );
    });

    return (
      <div>
        <Cover message={this.state.datas.title} name={this.state.datas.name} />  

        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-12 text-justify">
              {this.state.datas.description}
            </div>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="mt-5 col-12 text-center">
              <Link to="/contact" className="btn btn-dark">Nous contacter</Link>
            </div>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <h2 className="text-center pt-5 pb-2">{this.state.gallery.title}</h2>
            </div>
            <div className="col-12">
              <Gallery imgs={this.state.gallery.imgs} showThumbnails={true} galleryFolder="press"/>
            </div>            
          </div> 
              
          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <h2 className="text-center pt-5 pb-2">{this.state.pressKit.title}</h2>
            </div>
            <div className="col-12 text-justify">
              {this.state.pressKit.description}
            </div>                  
          </div> 

          <div className="row justify-content-center mt-4 pt-4">
            {pressKit} 
          </div> 

          <div className="row justify-content-center">
            <div className="col-12" data-aos="fade-up">
              {works.length > 0 ? <h2 className="text-center mt-5 mb-4">{this.state.workPress.title}</h2> : ''}
            </div>
            {works}
          </div>  
        </div>
      </div> 
    );
  }
}
