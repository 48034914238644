import React, { Component } from 'react';
import autobind from 'react-autobind';
import {
  Card,
  CardImg,
  CardBody,
  Badge
} from 'reactstrap';
import Accents from 'remove-accents';
import {Link} from 'react-router-dom';
import MemberSocialNetworks from './MemberSocialNetworks';

export default class Member extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  getRegularMember (member, index) {
    const roles = member.roles.map((role, index) => {
      if(role.type === 'CA'){
        return (
          <Badge color="secondary" key={index} className=" badge-ca m-1">{role.name}</Badge>
        );
      } else if(role.type === 'DIR' || role.type === 'RESP'){
        return (
          <Badge color="secondary" key={index} className="badge-dir m-1">{role.name}</Badge>
        );
      } else {
        return (
          <Link to={'/recrutement/'+role.linkId} key={index}><Badge color="secondary" className="badge-member m-1">{role.name}</Badge></Link>
        );
      }
    });

    const imageId = Accents.remove(member.firstname.toLowerCase()+'_'+member.lastname.toLowerCase());
    const image = require('../../assets/images/team/'+imageId+'.jpg');

    return (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4" key={index}>
        <Card className="h-100" data-aos="fade-up">
          <CardImg top width="100%" src={image} alt="Card image cap" />
          <CardBody>
            <h5 className="card-title">
              {member.firstname} {member.lastname}
              <MemberSocialNetworks member={member} pointBefore={'true'} />
            </h5>
            <div className="roles flex-column flex-nowrap">
              {roles}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }

  getEnrollLink () {
    return(
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4" data-aos="fade-up">
        <Link to="/recrutement">
          <div className="h-100 card-link">
            <p>Et si vous étiez le prochain ?</p>
            <button className="btn">Nous rejoindre</button>
          </div>
        </Link>
      </div>
    );
  }

  render () {
    return this.props.enrollLink ? this.getEnrollLink() : this.getRegularMember(this.props.member, this.props.index);
  }
}