import React, { Component } from 'react';
import autobind from 'react-autobind';
import Video from '../../components/common/Video';
import {Link} from 'react-router-dom';
import constants from '../../services/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactHtmlParser from 'react-html-parser';

export default class VideoModule extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      title : props.title,
      video : props.video,
      banner: props.banner
    };
  }

  render() {
    return (
      <div className="video-module">
        <div className="overlay-delimiter">
          <div className="overlay">
            <Video video={this.state.video} />
          </div>
        </div>
        <div className="content">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-6 h-100">
                <div className="d-flex align-items-center h-100">
                  <span className="h1" data-aos="fade-right">{ReactHtmlParser(this.state.title)}</span>
                </div>
              </div>
              <div className="col-lg-6 h-100 d-flex justify-content-end">
                <div className="banner">
                  <div className="about-video" data-aos="fade-up">
                    <div className="text-center mb-4">
                      <span className="h2">{ReactHtmlParser(this.state.banner.title)}</span>
                    </div>
                    <div className="text-justify mb-2">
                      <p>{ReactHtmlParser(this.state.banner.description)}</p>
                    </div>
                    <div className="text-center mb-2">
                      {
                        this.state.banner.link.path.includes('http') ? 
                          <a href={this.state.banner.link.path} className="btn btn-dark" target="_blank" rel="noopenner">{this.state.banner.link.text}</a> 
                          : 
                          <Link to={this.state.banner.link.path} className="btn btn-dark">{this.state.banner.link.text}</Link>
                      }
                    </div>
                  </div>
                  <div className="social-networks" data-aos="fade-up">
                    <div className="text-center mb-2">
                      <span className="h2">Nous retrouver</span>
                    </div>
                    <span className="rs">
                      <a href={constants.twitterLink} className="twitter colored"><FontAwesomeIcon icon={['fab', 'twitter']}/></a>
                      <a href={constants.facebookLink} className="facebook colored"><FontAwesomeIcon icon={['fab', 'facebook']}/></a>
                      <a href={constants.discordLink} className="discord colored"><FontAwesomeIcon icon={['fab', 'discord']}/></a>
                      <a href={constants.youtubeLink} className="youtube colored"><FontAwesomeIcon icon={['fab', 'youtube']}/></a>
                    </span>
                  </div>
                  <div className="contact text-center">
                    <Link to="/contact">Nous contacter</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}