import React, { Component } from 'react';
import autobind from 'react-autobind';
import Cover from '../../components/cover/Cover';
import {getPartnersDatas, getOrderedPartners} from '../../services/Partners';
import Partner from '../../components/partners/Partner';
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import {initAOS} from '../../services/Common';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class partners extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      partners: getPartnersDatas()
    };
  }

  componentDidMount(){
    initAOS(AOS);
  }

  componentWillReceiveProps (){
    AOS.refresh();
  }

  render() {

    const partnersList = getOrderedPartners().map((partner, index) => {
      return(
        <Partner partner={partner} key={index} />
      );
    });
    return (
      <div>
        <Cover message="Nos partenaires" name="partners" />
        <div className="container">
          <div className="row" data-aos="fade-up">
            {partnersList}
          </div>

          <div data-aos="fade-up">
            <div className="row">
              <div className="col-12">
                <h2 className="text-center pt-5 pb-2">{this.state.partners.title}</h2>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-justify" data-aos="fade-up">
                {ReactHtmlParser(this.state.partners.descriptionHtml)}
              </div>
            </div>

            <div className="row">
              <div className="mt-5 col-12 text-center">
                <Link to="/contact" className="btn btn-dark ">Nous contacter</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
