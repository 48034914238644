import React from 'react';

const Intervention =  ({title, desc, place, date, link, image}) => {
  const img = require('../../assets/images/workshop/interventions/'+image.path);
  return (
    <a href={link} className="intervention colored-border right grey">
      <img alt={image.title} src={img} />
      <div className="flex-item-fluid p-2">
        <p className="mb-0">
          <span className="title font-weight-bold">
            {title} ({date})
          </span>
        </p>
        <p>
          {place}
        </p>
        <span className="description text-justify">
          {desc}
        </span>
      </div>
    </a>
  );
};

export default Intervention;