import React, { Component } from 'react';
import autobind from 'react-autobind';
import Cover from '../../components/cover/Cover';
import {getOrderedProjectsResumes} from '../../services/Project';
import ProjectPoster from '../../components/projects/ProjectPoster';
import Shuffle from 'shufflejs';

import {initAOS} from '../../services/Common';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class Projects extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state={
      shuffleInstance: null
    };
  }

  componentDidMount(){
    initAOS(AOS);

    const element = document.querySelector('.my-shuffle-container');
    if(element){
      const shuffleInstance = new Shuffle(element, {
        itemSelector: '.project-poster'
      });
      this.setState({
        shuffleInstance,
        activeFilter: 'all'
      });
    }
  }

  componentWillReceiveProps (){ 
    AOS.refresh(); 
  }

  filter(e){    
    this.state.shuffleInstance.filter(e.target.name);
    this.setState({
      activeFilter: e.target.name
    });
  }

  render() {

    const posters = getOrderedProjectsResumes().map((project, index) => {
      return(
        <ProjectPoster project={project} key={index}/>
      );
    });

    return (
      <div>
        <Cover message="Nos projets" name="projects"/>
        <div className="container position-relative pt-3 pb-2">
          <div className="row">
            <div className="col-12 d-flex justify-content-center w-100 mb-4 buttons">
              <button type="button" className={this.state.activeFilter === 'all' ? 'btn btn-dark mr-2' : 'btn btn-outline-dark mr-2'} onClick={this.filter} name="all">Tous</button>
              <button type="button" className={this.state.activeFilter === 'serie' ? 'btn btn-teal mr-2' : 'btn btn-outline-teal mr-2'} onClick={this.filter} name="serie">Séries</button>
              <button type="button" className={this.state.activeFilter === 'court' ? 'btn btn-red mr-2' : 'btn btn-outline-red mr-2'} onClick={this.filter} name="court">Court-métrages</button>
              <button type="button" className={this.state.activeFilter === '48h' ? 'btn btn-indigo mr-2' : 'btn btn-outline-indigo mr-2'} onClick={this.filter} name="48h">48 heures</button>
              <button type="button" className={this.state.activeFilter === 'emission' ? 'btn btn-orange' : 'btn btn-outline-orange'} onClick={this.filter} name="emission">Émissions</button>
            </div>
          </div>
          <div data-aos="fade-up">
            <div className="row my-shuffle-container mt-4 pt-4">
              {posters}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
