import React from 'react';

const Question =  ({question, index}) => {
  return (
    <div key={index}  data-aos="fade-up">
      <h5 className="mt-4 mb-3">{question.request}</h5>
      <p className="text-muted">
        {question.response}
      </p>
    </div>
  );
};

export default Question;