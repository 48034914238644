import React, { Component } from 'react';
import autobind from 'react-autobind';
import {Link} from 'react-router-dom';

export default class ServicesModule extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      services: props.services
    };
  }

  render() {

    

    const services = this.state.services.map((service, index) => {
      const img = require('../../assets/images/home/' + service.image);

      return (
        <div className="col-lg-4 col-md-12 mb-5 mt-4" key={index}>
          <div className={'card h-100 workshop colored-border top '+service.color} data-aos="fade-up">
            <div className="card-header">
              <p className="h5 card-title">{service.title}</p>
              <p className="small card-subtitle">{service.subtitle}</p>
            </div>
            <img className="w-100" src={img} alt={service.title}></img>
            <div className="card-body">
              <div className="mb-3">
                <p className="card-text text-justify">{service.description}</p>
              </div>
              <Link to={service.url} className="btn btn-dark w-100">En savoir plus</Link>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="services-module mt-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="h3 text-center">Nos services</p>
            </div>
          </div>
          <div className="row justify-content-center">
            {services}
          </div>
        </div>
      </div>
    );
  }
}