import { getDataFromJson } from './Helper';

export const getDatas = () => {
  return getDataFromJson('translation', 'data');
};

export const getSubtitlesModule = () => {
  return getDataFromJson('translation', 'subtitles');
};

export const getExampleModule = () => {
  return getDataFromJson('translation', 'example');
};

export const getCounterparts = () => {
  return getDataFromJson('translation', 'counterparts');
};

export const getWorkModule = () => {
  return getDataFromJson('translation', 'works');
};