import React from 'react';
import ProjectCover from './ProjectModuleCover';

const Credits =  ({credits}) => {
  const team = credits.team.map((e, index) => {
    const users = e.users.map((u, index) => {
      return <span className="d-block" key={index}>{u}</span>;
    });
    return (
      <div key={index}>
        <p className="font-weight-bold mb-0">{e.role}</p>
        <p className="color-grey">{users}</p>
      </div>
    );      
  });

  const cast = credits.cast.map((e, index) => {
    return (
      <div key={index}>
        <p className="font-weight-bold mb-0">{e.role}</p>
        <p className="color-grey">{e.actor}</p>
      </div>
    );      
  });

  const specialThanks = credits.specialThanks.map((e, index) => {
    return (
      <div key={index}>
        <p className="color-grey mb-0">{e}</p>
      </div>
    );      
  });

  const mods = credits.mods.map((e, index) => {
    return (
      <div key={index}>
        <p className="color-grey mb-0">{e}</p>
      </div>
    );      
  });

  const partners = credits.partners.map((e, index) => {
    return (
      <div key={index}>
        <p className="color-grey mb-0">{e}</p>
      </div>
    );      
  });

  return(
    <div id="credits">
      <ProjectCover message={credits.cover.name} imageName={credits.cover.image+'_credits'} textColor={credits.cover.color}/>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center text-uppercase" data-aos="fade-up">
            {team}
            <p className="h5 mt-5 mb-5">Acteurs</p>
            {cast}
            {specialThanks.length > 0 ? <p className="h5 mt-5 mb-5">Remerciements</p> : ''} 
            {specialThanks}
            <p className="h5 mt-5 mb-5">Mods utilisés</p>
            {mods}
            <p className="h5 mt-5 mb-5">Partenaires</p>
            {partners}
          </div>
        </div>
      </div>
    </div>
    
  );
  
};

export default Credits;