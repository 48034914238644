import React, { Component } from 'react';
import autobind from 'react-autobind';
import Cover from '../../components/cover/Cover';
import ContactFormContainer from '../../components/contact/ContactFormContainer';

import {initAOS} from '../../services/Common';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class Home extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount(){
    initAOS(AOS); 
  }

  componentWillReceiveProps (){ 
    AOS.refresh(); 
  }

  render() {
    return (
      <div>
        <Cover message="Nous contacter" name="contact" />
        <div className="container">
          <ContactFormContainer formName="ContactForm" formColor="grey"/>
        </div>
      </div>
    );
  }
}