import React from 'react';
import VideoGallery from './VideoGallery';

const ProjectVideoModule = ({module}) => {
  
  const text = module.text.length > 1 ? <div className="col-12"> {module.text} </div> : '';
  return (
    <div className="row" data-aos="fade-up">
      {text}
      <div className="col-12">
        {module.videos.length > 0 ? <VideoGallery videos={module.videos} className="mt-4"/> : ''}
      </div>
    </div> 
  );
};

export default ProjectVideoModule;