import { getDataFromJson } from './Helper';

export const getDatas = () => {
  return getDataFromJson('doublage', 'data');
};

export const getMoreInformations = () => {
  return getDataFromJson('doublage', 'moreInformation');
};

export const getOurVoices = () => {
  return getDataFromJson('doublage', 'ourVoices');
};

export const getWorkModule = () => {
  return getDataFromJson('doublage', 'workModule');
};

export const getCounterparts = () => {
  return getDataFromJson('doublage', 'counterparts');
};