import React from 'react';

export default class Cover extends React.Component {
  constructor(props) {
    super(props);

    const textColorAuto = this.props.textColor ? this.props.textColor : 'light';
    const negMargin = this.props.withNegMargin ? true : false;
    this.state = {
      message      : this.props.message,
      image        : this.props.image,
      withNegMargin: negMargin,
      textColor    : textColorAuto,
      name         : this.props.name,
      jobOpen      : this.props.jobOpen
    };
  }
  
  render() {
    const image = require('../../assets/images/covers/cover_' + this.state.name + '.jpg');

    let jobBadge = '';
    if(this.state.jobOpen !== undefined) {
      jobBadge = <span className={this.state.jobOpen ? 'badge badge-success' : 'badge badge-danger'}>{this.state.jobOpen ? 'Ouvert' : 'Fermé'}</span>;
    }

    return (
      <div className={this.state.withNegMargin ? 'head with-neg-margin' : 'head'}>
        <img src={image} alt="cover" className="img-fluid" />
        <div className="cover-message">
          <h1 className={'text-' + this.state.textColor}>{this.state.message}</h1>
          {jobBadge}
        </div>
      </div>
    );
  }
}