import {getDatas, getGallery, getPressKitModule, getWorkModule} from '../api/Press';

export const getPressPageDatas = () => {
  return getDatas();
};

export const getGalleryDatas = () => {
  return getGallery();
};

export const getPressKitModuleDatas = () => {
  return getPressKitModule();
};

export const getWorkModuleDatas = () => {
  return getWorkModule();
};