import {getDatas, getMoreInformations, getOurVoices, getWorkModule, getCounterparts} from '../api/Doublage';

export const getDoublagePageDatas = () => {
  return getDatas();
};

export const getMoreInformationsDatas = () => {
  return getMoreInformations();
};

export const getOurVoicesDatas = () => {
  return getOurVoices();
};

export const getWorkModuleDatas = () => {
  return getWorkModule();
};

export const getCounterpartsDatas = () => {
  return getCounterparts();
};