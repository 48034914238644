import React from 'react';

const ContactForm = ({name, value, labelContent, onChange}) => {
  return (
    <div className="custom-control custom-checkbox">
      <input type="checkbox" className="custom-control-input" id={name + '_' + value} name={name + '_' + value} value={value} onChange={onChange}/>
      <label className="custom-control-label" htmlFor={name + '_' + value}>{labelContent}</label>
    </div>
  );
};

export default ContactForm;