import { getDataFromJson } from './Helper';

export const getMembers = () => {
  return getDataFromJson('members', 'members');
};

export const getExternalActors = () => {
  return getDataFromJson('members', 'externalActors');
};

export const getHelpers = () => {
  return getDataFromJson('members', 'helpers');
};