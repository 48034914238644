import {getMembers, getExternalActors, getHelpers} from '../api/Members';

export const ROLE_CA = 'CA';
export const ROLE_CA_NUM = 0;
export const ROLE_CA_PR = 'PR';
export const ROLE_CA_CRH = 'CRH';
export const ROLE_CA_CP = 'CP';
export const ROLE_CA_CD = 'CD';
export const ROLE_CA_CI = 'CI';
export const ROLE_DIR = 'DIR';
export const ROLE_DIR_NUM = 2;
export const ROLE_RESP = 'RESP';
export const ROLE_RESP_NUM = 1;
export const ROLE_MEMBER = 'MEMBER';
export const ROLE_MEMBER_NUM = 3;

export const getOrderedMembers = () => {
  let members = getMembers();
  members = members.sort(function (a, b){
    if(getNumRoleMaxMember(a) === getNumRoleMaxMember(b)){
      if(getNumRoleMaxMember(a) === ROLE_CA_NUM){
        //PRESIDENT
        if (a.roles.filter(role => role.abstract === ROLE_CA_PR).length > 0) return -1;
        if (b.roles.filter(role => role.abstract === ROLE_CA_PR).length > 0) return 1;        
        //CONSEILLER PROD
        if (a.roles.filter(role => role.abstract === ROLE_CA_CP).length > 0) return -1;
        if (b.roles.filter(role => role.abstract === ROLE_CA_CP).length > 0) return 1;
        //CONSEILLER RELATIONS HUMAINES
        if (a.roles.filter(role => role.abstract === ROLE_CA_CRH).length > 0) return -1;
        if (b.roles.filter(role => role.abstract === ROLE_CA_CRH).length > 0) return 1;
        //CONSEILLER AU DIALOGUE
        if (a.roles.filter(role => role.abstract === ROLE_CA_CD).length > 0) return -1;
        if (b.roles.filter(role => role.abstract === ROLE_CA_CD).length > 0) return 1;
      }
      return (a.lastname < b.lastname) ? -1 : (a.lastname > b.lastname) ? 1 : 0;
    } else {
      return getNumRoleMaxMember(a) - getNumRoleMaxMember(b);
    }
  });
  return members;
};

export const getNumRoleMaxMember = (member) => {
  let max = ROLE_MEMBER_NUM;
  for(let i = 0; i < member.roles.length; i++){
    const role = member.roles[i];
    if(role.type === ROLE_CA){
      return ROLE_CA_NUM;
    } else if (role.type === ROLE_DIR){
      max = ROLE_DIR_NUM;
    } else if (role.type === ROLE_RESP && max !== ROLE_DIR_NUM){
      max = ROLE_RESP_NUM;
    }
  }

  return max;
};

export const getOrderedExternalActors = () => {
  return getExternalActors().sort(function (a, b){
    return (a.pseudo < b.pseudo) ? -1 : (a.pseudo > b.pseudo) ? 1 : 0;
  });
};

export const getOrderedHelpers = () => {
  return getHelpers().sort(function (a, b){
    return (a.pseudo < b.pseudo) ? -1 : (a.pseudo > b.pseudo) ? 1 : 0;
  });
};

export const hasSocialNetwork = (member) => {
  return member.socials && member.socials.length > 0;
};