import React, { Component } from 'react';
import autobind from 'react-autobind';
import Recaptcha from 'react-recaptcha';
import {Alert, Form, Button, Jumbotron} from 'reactstrap';
import DoublageForm from './DoublageForm';
import TranslationForm from './TranslationForm';
import WorkshopForm from './WorkshopForm';
import ContactForm from './ContactForm';
import * as emailjs from 'emailjs-com';

export default class contactForm extends Component {

  constructor(props) {
    super(props);
    autobind(this);

    let type = 'question'; //set to question by default
    if(props.requestType){
      type =  props.requestType;
    }

    let FormTemplate;
    let formType = '';
    if(props.formName === 'DoublageForm'){
      FormTemplate = DoublageForm;
      formType = 'Demande de doublage';
    } else if (props.formName === 'WorkshopForm'){
      FormTemplate = WorkshopForm;
      formType = 'Demande d\'atelier';
    } else if (props.formName === 'TranslationForm'){
      FormTemplate = TranslationForm;
      formType = 'Demande de traduction';
    } else {
      FormTemplate = ContactForm;
      formType = 'Contact';
    }

    this.state = {
      requestType  : type,         // Information for the email
      recaptcha    : '',           // For the bot
      inputsVars   : {},           // Values for each input : {inputName: inputValue, ...}
      error        : '',           // Error from submit
      returnMessage: '',           // Return message from submit
      FormTemplate : FormTemplate, // component of the template
      formType     : formType,
      formColor    : props.formColor
    };
  }

  onChange(e){
    if(e.target.name === 'requestType'){
      this.setState({
        requestType: e.target.value
      });
    } else {
      const inputs = this.state.inputsVars;
      inputs[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      this.setState({
        inputsVars: inputs
      });
    }
  }

  recaptcha(e){
    if(e){
      this.setState({
        recaptcha: e
      });
    }
  }

  recaptchaLoaded() {
    console.log('Captcha Loaded');
  }

  submit(e){
    e.preventDefault();
    const params = this.state.inputsVars;
    params.request_type = this.state.requestType;
    params.form_type = this.state.formType;

    if (this.state.recaptcha && this.state.recaptcha !== 'error'){
      if(this.checkProperties(params)){
        this.sendEmail(this.transformBooleanToString(params));
      } else {
        this.postSubmitError('Un champ demandé manque à l\'appel.');
      }
    } else {
      this.setState({
        recaptcha: 'error'
      });
    }
  }

  transformBooleanToString(obj) {
    for (const key in obj) {
      if (typeof obj[key] === 'boolean'){
        obj[key] = obj[key] ? 'intéressé' : 'non intéressé';
      }
    }
    return obj;
  }

  postSubmitError(error) {
    this.setState({
      error: error
    });
  }

  postSubmitConfirmation() {
    this.setState({
      returnMessage: 'Votre demande a bien été envoyée ! Notre équipe vous répondra le plus vite possible à l\'adresse email indiquée.'
    });
  }

  sendEmail(params) {
    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID;

    emailjs.send(serviceID, 'contact_mail', params, userID).then(() => {
      this.postSubmitConfirmation();
      emailjs.send(serviceID, 'confirmation', {from_email: params.from_email}, userID);
    }).catch(
      this.postSubmitError
    );
  }

  checkProperties(obj){
    for (const key in obj) {
      if (typeof obj[key] === 'undefined'){
        return false;
      }
    }
    return true;
  }

  getSendButton() {
    if(this.state.error){
      return <span className="btn btn-danger btn-block">Une erreur est survenue !</span>;
    } else if (this.state.returnMessage){
      return <span className="btn btn-success btn-block">Envoyé !</span>;
    } else {
      return <Button color="dark" block>Envoyer</Button>;
    }
  }

  render() {
    const recaptchaError = <Alert color="danger">Oops ! Vous avez oublié de compléter le captcha :/</Alert>;
    const errorMessage = <Alert color="danger">Oops ! Nous avons rencontré un problème : {this.state.error}<br/>Vous pouvez envoyer une demande de contact à l'adresse contact@creativecommunity.fr </Alert>;
    const returnMessage = <Alert color="success">{this.state.returnMessage}</Alert>;
    const FormTemplate = this.state.FormTemplate;
    return (
      <div>
        <div className="container">
          <Form onSubmit={this.submit}>
            <div className="row">
              <Jumbotron className={'col-lg-6 offset-lg-3 col-sm-12 offset-sm-0 colored-border top ' + this.state.formColor} data-aos="fade-up">
                {this.state.recaptcha === 'error' ? recaptchaError : ''}
                {this.state.error ? errorMessage : ''}
                {this.state.returnMessage ? returnMessage : ''}

                <FormTemplate onChange={this.onChange} />

                <Recaptcha
                  sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                  verifyCallback={this.recaptcha}
                  onloadCallback={this.recaptchaLoaded} //if not set, captcha is not displayed
                  render="explicit"
                  hl="fr"
                />

                <div className="btn-group-lg pt-3">
                  {this.getSendButton()}
                </div>

                <p className="pt-2"><span className="text-danger">*</span> Champ obligatoire</p>
              </Jumbotron>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}