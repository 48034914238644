import React from 'react';
import {Link} from 'react-router-dom';
import constants from '../../services/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class MyFooter extends React.Component {
  render() {
    const year = (new Date()).getFullYear();

    return (
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <span>
                <Link to="/presse">Presse</Link>
                <Link to="/mentions-legales">Mentions légales</Link>
                <Link to="/contact">Nous contacter</Link>
              </span>
              <span className="rs">
                <a href={constants.twitterLink} className="twitter"><FontAwesomeIcon icon={['fab', 'twitter']} size="lg"/></a>
                <a href={constants.facebookLink} className="facebook"><FontAwesomeIcon icon={['fab', 'facebook']} size="lg"/></a>
                <a href={constants.discordLink} className="discord"><FontAwesomeIcon icon={['fab', 'discord']} size="lg"/></a>
                <a href={constants.youtubeLink} className="youtube"><FontAwesomeIcon icon={['fab', 'youtube']} size="lg"/></a>
              </span>
            </div>
            <div className="col-12">
              <p className="mt-4 mb-0">© Le Cube à Essai - {year}</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}