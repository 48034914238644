import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const ArticleElementBlank = ({title, description, imagePath, directionToTheLeft, color}) => {
  const image = require('../../assets/images/'+imagePath);
  
  return (
    <div className="row article-element-blank mt-4" data-aos="fade-up">
      <div className="col-12">
        <div className={'row jumbotron colored-border ' + (directionToTheLeft==='true' ? 'right ' : 'left ') + color}>
          {
            directionToTheLeft==='true' ?
              <div className="col-lg-6 col-sm-12 mb-4">
                <img className="img-fluid" alt={image} src={image}></img>
              </div>
              : ''
          } 
          <div className="col-lg-6 col-sm-12 d-flex align-items-top text-justify flex-column justify-content-top">
            <h2 className="text-center mb-4">{title}</h2>
            <p>{ReactHtmlParser(description)}</p>
          </div>
          {
            directionToTheLeft==='false' ?
              <div className="col-lg-6 col-sm-12 mb-4">
                <img className="img-fluid" alt={image} src={image}></img>
              </div>
              : ''
          }        
        </div>
      </div>
    </div>
  );
};

export default ArticleElementBlank;