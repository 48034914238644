import React from 'react';
import {Link} from 'react-router-dom';
import {getStringDate} from '../../services/Common';

const ProjectPoster =  ({project, index}) => {

  const image = require('../../assets/images/projects/posters/affiche_'+project.id+'.jpg');

  let groups = '[';
  project.type.forEach(t => {
    groups += '"'+t+'"';
    if(project.type[project.type.length - 1] !== t){
      groups += ',';
    }
  });
  groups += ']';

  const dots = project.type.map((t, index) => {
    if(t === 'serie'){
      return (<span className="teal-dot" key={index}></span>);
    } else if(t === 'court'){
      return (<span className="red-dot" key={index}></span>);
    } else if(t === '48h'){
      return (<span className="indigo-dot" key={index}></span>);
    } else if(t === 'emission'){
      return (<span className="orange-dot" key={index}></span>);
    }
     else 
      return (<span></span>);
  });

  return (
    <div className="col-lg-4 col-md-6 col-sm-12 project-poster" key={index} data-groups={groups}>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className="d-inline-block">
          <Link to={'/projets/'+project.id} className="d-inline-block position-relative">
            <img src={image} alt={project.name} className="img-fluid"/>
            <span className="position-absolute d-flex justify-content-center align-items-center h-100">
              <span>{project.description}</span>
            </span>
          </Link>
          <p className="pt-2 m-0 text-center">
            {project.name}
            <br/>
            <span className="small text-secondary">{getStringDate(new Date(project.release), true, false)}</span>
          </p>
          <p className="pl-2 pr-2 m-0 poster-dots">{dots}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectPoster;