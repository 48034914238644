import React from 'react';
import InputForm from './InputForm';
import {FormGroup, Label} from 'reactstrap';
import CheckboxForm from './CheckboxForm';

const WorkshopForm = ({onChange}) => {
  return (
    <div>
      <InputForm name="from_name" labelContent="Nom et prénom du porteur de projet" type="text" required={true} onChange={onChange} />
      <InputForm name="from_email" labelContent="Adresse mail du porteur de projet (pour vous recontacter)" type="email" required={true} onChange={onChange} />
      <InputForm name="from_structure" labelContent="Nom de l'organisme ou de la structure" type="text" required={true} onChange={onChange} />
      <InputForm name="workshop_detail" labelContent="Présentation détaillée du projet" type="textarea" required={true} onChange={onChange} />
      <InputForm name="workshop_place" labelContent="Lieu d'organisation de l'atelier" type="text" onChange={onChange} />
      <InputForm name="workshop_dates" labelContent="Date(s)" type="text" onChange={onChange} />

      <FormGroup>
        <Label for="module">Module(s) qui vous intéressent</Label>
        <CheckboxForm name="module" value="atelier_initiation" labelContent="Atelier d'initiation" onChange={onChange}/>
        <CheckboxForm name="module" value="atelier_realisation" labelContent="Atelier réalisation" onChange={onChange}/>
        <CheckboxForm name="module" value="conference" labelContent="Conférence" onChange={onChange}/>
        <CheckboxForm name="module" value="projection" labelContent="Projection de machinima" onChange={onChange}/>
      </FormGroup>

      <InputForm name="message" labelContent="Autres informations éventuelles" type="textarea" onChange={onChange} />
    </div>
  );
};

export default WorkshopForm;