import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';
import { withCookies } from 'react-cookie';
import autobind from 'react-autobind';

const cookieAccess = 'cookie_access';

export class CookiesBanner extends Component {
  constructor(props) {
    super(props);
    const {cookies} = props;

    let optionSelected = true;

    if(cookies.get(cookieAccess) === undefined) {
      optionSelected = false;
    }

    this.state = {
      acceptCookies: optionSelected ? JSON.parse(cookies.get(cookieAccess)) : false,
      optionSelected
    };
    autobind(this);
  }

  initAnalytics() {
    if(this.state.acceptCookies){
      ReactGA.initialize(process.env.REACT_APP_GA_ID);
    }
  }

  acceptAnalytics() {
    this.setState({
      acceptCookies : true,
      optionSelected: true
    });
    this.props.cookies.set(cookieAccess, true);
  }

  refuseAnalytics() {
    this.setState({
      acceptCookies : false,
      optionSelected: true
    });
    this.props.cookies.set(cookieAccess, false);
  }

  render () {
    const {children} = this.props;
    const {optionSelected} = this.state;
    this.initAnalytics();

    return(
      <Fragment>
        {!optionSelected && (
          <div className="cookie-nav">
            <div className="container">
              <div className="cookie-text">
                Nous utilisons les cookies pour analyser le trafic, rien de plus. Merci de cliquer sur le bouton Accepter pour nous permettre d'améliorer notre site web.
              </div>
              <div className="cookie-buttons">
                <button className="btn btn-dark" onClick={this.acceptAnalytics}>Accepter</button>
                <button className="btn btn-secondary" onClick={this.refuseAnalytics}>Refuser</button>
              </div>
            </div>
          </div>
        ) }
        {children}
      </Fragment>
    );
  }
}

export default withCookies(CookiesBanner);