import React, { Component } from 'react';
import autobind from 'react-autobind';
import Cover from '../../components/cover/Cover';
import Workshop from '../../components/workshop/Workshop';
import Intervention from '../../components/workshop/Intervention';
import GoogleMapReact from 'google-map-react';
import ArticleElement from '../../components/common/ArticleElement';
import {initAOS} from '../../services/Common';
import VideoGallery from '../../components/common/VideoGallery';
import ContactFormContainer from '../../components/contact/ContactFormContainer';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {getWorkshopPageDatas, getAimModuleDatas, getConlusionModuleDatas, getillustrationsModuleDatas, getInterventionsDatas, getWorkshopsModuleDatas} from '../../services/Workshop';

import AOS from 'aos';
import 'aos/dist/aos.css';

export default class Translation extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      datas         : getWorkshopPageDatas(),
      workshopModule: getWorkshopsModuleDatas(),
      interventions : getInterventionsDatas(),
      aim           : getAimModuleDatas(),
      conclusion    : getConlusionModuleDatas(),
      illustrations : getillustrationsModuleDatas()
    };
  }

  componentDidMount(){
    initAOS(AOS);

    document.querySelectorAll('.scroll').forEach(e => {
      e.addEventListener('scroll', this.handleScroll);
    });

  }

  componentWillUnmount() {
    document.querySelectorAll('.scroll').forEach(e => {
      e.removeEventListener('scroll', this.handleScroll);
    });
  }

  componentWillReceiveProps (){
    AOS.refresh();
  }

  handleScroll (e){
    const s = e.target.querySelector('.scroller-bottom svg');
    if(e.target.clientHeight >= e.target.scrollHeight - e.target.scrollTop) {
      s.className.baseVal = 'svg-inline--fa fa-angle-down fa-w-10 transition-linear rotate-180'; //classes generated by React at start
    } else {
      s.className.baseVal = 'svg-inline--fa fa-angle-down fa-w-10 transition-linear';
    }
  }

  render() {

    const workshops = this.state.workshopModule.workshops.map((workshop, index) => {
      return (
        <Workshop workshop={workshop} index={index} key={index}/>
      );
    });

    const img = require('../../assets/images/workshop/marker.png');
    const markers = this.state.interventions.map((intervention, index) => {
      return <div className="google-marker" lat={intervention.lat} lng={intervention.lng} key={index}><img src={img} alt="Marker Creative Community"/></div>;
    });

    const interventions = this.state.interventions.map((intervention, index) => {
      return <Intervention title={intervention.id} desc={intervention.desc} link={intervention.link} date={intervention.date} place={intervention.place} image={intervention.image} key={index}/>;
    });

    return (
      <div>
        <Cover message={this.state.datas.title} name={this.state.datas.name} />
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-12 text-justify">
              {this.state.datas.description}
            </div>
          </div>
          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <h2 className="text-center pt-5 pb-2">Nos interventions</h2>

              <div className="row">
                <div className="col-lg-6 col-md-12 scroll">
                  {interventions}
                  <div className="scroller-bottom">
                    <FontAwesomeIcon icon={['fas', 'angle-down']} className="transition-linear"/>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div style={{ height: '100%', width: '100%' }} className="only-desktop">
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API_KEY }}
                      defaultCenter={
                        {lat: 46.644215, lng: 2.5275223}
                      }
                      defaultZoom={5.5}
                    >
                      {markers}
                    </GoogleMapReact>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <h2 className="text-center pt-5 pb-2">{this.state.workshopModule.title}</h2>
            </div>
            <div className="col-12">
              <p className="text-justify">{ReactHtmlParser(this.state.workshopModule.description)}</p>
            </div>
            <div className="col-12">
              <div className="row">
                {workshops}
              </div>
            </div>
          </div>

          <div>
            <ArticleElement title={this.state.aim.title} description={this.state.aim.description} imagePath={this.state.aim.image} directionToTheLeft={this.state.aim.toTheLeft}/>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <h2 className="text-center pt-5 pb-2">{this.state.illustrations.title}</h2>
            </div>

            <div className="col-12">
              <div className="justify-content-center text-justify">
                {this.state.illustrations.description}
              </div>
            </div>

            {this.state.illustrations.videos.length > 0 ? <div className="col-12"><VideoGallery videos={this.state.illustrations.videos} className="mt-4"/></div> : ''}
          </div>

          <div id="c_est_vous_qui_choisissez">
            <ArticleElement title={this.state.conclusion.title} description={this.state.conclusion.description} imagePath={this.state.conclusion.image} directionToTheLeft={this.state.conclusion.toTheLeft}/>
          </div>

          <h2 id="form" className="text-center mt-5 mb-4">Parlez-nous de votre projet</h2>
          <ContactFormContainer formName="WorkshopForm" requestType="atelier" formColor="indigo" />
        </div>
      </div>
    );
  }
}
