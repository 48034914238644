import { getDataFromJson } from './Helper';

export const getDatas = () => {
  return getDataFromJson('workshop', 'data');
};

export const getAimModule = () => {
  return getDataFromJson('workshop', 'aim');
};

export const getConclusionModule = () => {
  return getDataFromJson('workshop', 'conclusion');
};

export const getillustrationsModule = () => {
  return getDataFromJson('workshop', 'illustrations');
};

export const getWorkshopsModule = () => {
  return getDataFromJson('workshop', 'workshops');
};

export const getInterventions = () => {
  return getDataFromJson('workshop', 'interventions');
};