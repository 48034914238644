import React, { Component } from 'react';
import autobind from 'react-autobind';
import {getProjectsResumesNumbers} from '../../services/Project';
import {Link} from 'react-router-dom';

export default class ProjectModule extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      title   : props.title,
      projects: getProjectsResumesNumbers(5)
    };
  }

  render() {

    const projects = this.state.projects.map((project, index) => {
      let projectGif = '';
      try{
        projectGif = require('../../assets/images/home/'+ project.id +'.gif');
      } catch(e) {
        //just to avoid crash
      }
      const reverse = index % 2 === 0;

      const first = this.state.projects[0].id === project.id;

      return (
        <div className={'col-md-12 pb-4 project ' + (first ? 'col-lg-12' : 'col-lg-6')} data-aos={reverse ? 'fade-up' : 'fade-up'} data-aos-duration="2500" key={index}>
          <div className={'jumbotron h-100 ' + project.id}>
            <div className="row h-100">
              <div className={'col-md-12 d-flex align-items-center ' + (first ? 'col-lg-4' : 'col-lg-6')}>
                {projectGif ?
                  <img src={projectGif} alt={project.id} className="w-100"/>
                  : ''}
              </div>
              <div className={'col-md-12 d-flex justify-content-center flex-column ' + (first ? 'col-lg-8' : 'col-lg-6')}>
                <div className={first ? '' : 'd-flex flex-column flex-grow-1'}>
                  {first ? <p className="h6">Notre dernier projet</p> : ''}
                  <p className="h4 mb-3">{project.name}</p>
                  <p className="text-justify flex-grow-1 d-flex align-items-center">
                    {project.description}
                  </p>
                </div>
                <p className="text-center"><Link to={'/projets/' + project.id} className="btn btn-dark btn-home">Découvrir {project.name}</Link></p>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="project-module">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="mb-5 text-center">{this.state.title}</h3>
            </div>
          </div>
          <div className="row justify-content-center">
            {projects}
          </div>
        </div>
        <div className="projects-link">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <Link to="/projets" className="btn btn-lg btn-dark" data-aos="fade-up">Découvez nos autres projets</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}