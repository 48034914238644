import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const ProjectSoundModule = ({module}) => {
  
  const text = module.text.length > 1 ? module.text : '';

  const soundcloud = module.soundcloud.map((sc, map) => {
    return(
      <div className="col-lg-6 mb-4" key={map}>
        <iframe title={sc.title} width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src={'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/'+sc.id+'&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'}></iframe>
      </div>
    );
  });

  return (
    <div className="row" data-aos="fade-up">
      <div className="col-12 mb-3 text-justify">{ReactHtmlParser(text)}</div>
      {soundcloud}
    </div> 
  );
};

export default ProjectSoundModule;