import React, { Component } from 'react';
import autobind from 'react-autobind';
import {
  Card,
  CardImg,
  CardBody
} from 'reactstrap';
import Accents from 'remove-accents';

import {initAOS} from '../../services/Common';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class Partner extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount(){
    initAOS(AOS); 
  }

  componentWillReceiveProps (){ 
    AOS.refresh(); 
  }

  render () {
    const partnerImage = require('../../assets/images/partners/'+this.props.partner.image);

    const partnerId = Accents.remove(this.props.partner.name.toLowerCase());
    return(
      
      <div className="col-lg-6 col-sm-12 pb-4" index={this.props.index}>
        <Card className={'h-100 border-bottom-'+ partnerId} data-aos="fade-up">
          <h5 className="card-title mt-4 text-center">
            {this.props.partner.name}
          </h5>
          <div className="logo-wrapper">
            <CardImg top width="100%" src={partnerImage} alt={'image du partenaire '+this.props.partner.name} className="p-4 logo-partner" />
          </div>
          <CardBody>
            <div className="card-text text-justify h100">
              {this.props.partner.description} 
            </div>
          </CardBody>
          <p className="pt-3 text-center"><a href={this.props.partner.url}>Voir le site web</a></p>
        </Card>
      </div>
    );
  }
}