import { getDataFromJson } from './Helper';

export const getDatas = () => {
  return getDataFromJson('press', 'data');
};

export const getGallery = () => {
  return getDataFromJson('press', 'gallery');
};

export const getPressKitModule = () => {
  return getDataFromJson('press', 'pressKit');
};

export const getWorkModule = () => {
  return getDataFromJson('press', 'works');
};