import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import {Link} from 'react-router-dom';
import logo from '../../assets/images/logos/logo_with_name.png';
import autobind from 'react-autobind';
import { Helmet } from 'react-helmet';


export default class MyNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    autobind(this);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  close() {
    this.setState({
      isOpen: false
    });
  }
  render() {
    return (
      <Navbar color="light" light expand="lg" className="fixed-top">
        <Helmet>
          <link rel="canonical" href={`https://www.lecubeaessai.fr${window.location.pathname}`} />
        </Helmet>
        <NavbarBrand tag={Link} to="/" onClick={this.close}><img src={logo} className="d-inline-block align-top" alt="logo" /></NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem tag={Link} to="/" className="nav-link">
              Accueil
            </NavItem>
            <UncontrolledDropdown nav inNavbar>         
              <DropdownToggle tag="span" className="nav-link">
                Le studio
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag={Link} to="/studio/presentation" onClick={this.close}>
                  Présentation
                </DropdownItem>
                <DropdownItem tag={Link} to="/studio/equipe" onClick={this.close}>
                  L'équipe
                </DropdownItem>
                <DropdownItem tag={Link} to="/studio/partenaires" onClick={this.close}>
                  Nos partenaires
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink tag={Link} to="/projets" onClick={this.close}>Nos projets</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle tag="span" className="nav-link">
                Nos services
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag={Link} to="/services/ateliers" onClick={this.close}>
                  Ateliers
                </DropdownItem>
                <DropdownItem tag={Link} to="/services/traduction" onClick={this.close}>
                  Traduction
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink tag={Link} to="/recrutement" onClick={this.close} className="animation-snooze">Recrutement</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}