import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {
  Jumbotron,
  Button
} from 'reactstrap';
import {getRandomErrorMessage} from '../../services/PageNotFound';

class ErrorPageNotFound extends Component {
  render() {
    const message = getRandomErrorMessage();
    return (
      <div className="container pt-2">
        <Jumbotron className="jumb-error error">
          <h1 className="display-3 text-danger">404</h1>
          <p className="lead">Page non trouvée </p>
          <hr className="my-2" />
          <p>{message}</p>
          <p className="lead">
            <Button color="danger" tag={Link} to="/">Retourner à l'accueil</Button>
            <Button color="link" tag={Link} to="/contact" className="text-info">Nous contacter</Button>
          </p>
        </Jumbotron>
      </div>
    );
  }
}

export default ErrorPageNotFound;