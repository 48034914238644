import React, { Component } from 'react';
import autobind from 'react-autobind';
import Cover from '../../components/cover/Cover';
import {Link} from 'react-router-dom';

export default class Presentation extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  render() {

    const img1 = require('../../assets/images/presentation/presentation_1.jpg');
    const img2 = require('../../assets/images/presentation/machinima.jpg');
    const img3 = require('../../assets/images/presentation/Minecraft.jpg');
    const img4 = require('../../assets/images/presentation/McHorses_Mods.png');
    return (
      <div>
        <Cover message="Qui sommes-nous ?" name="presentation"/>
        <div className="container ">
          <div className="text-justify">
            <div className="row">
              <div className="col-lg-12">
                <p>
                  <strong>
                    Le Cube à Essai (anciennement Creative Community) est un studio de développement de projets artistiques et créatifs spécialisé dans le contenu audiovisuel sur internet fondé début 2013 par Benjamin Maublanc et Lucien Gillonnier. Le studio produit et réalise notamment des machinimas, qui sont des films construits à partir de séquences vidéo capturées à l'intérieur d'espaces virtuels en temps réel – comme des jeux vidéo par exemple.
                  </strong>
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12" data-aos="fade-up">
                <h2 className="text-center mt-5 mb-4">L'histoire de Le Cube à Essai</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12" data-aos="fade-up">
                <p className="mt-3">
                  Suite à la création de « StarCube : Galaxy » – une web-série de science-fiction, et à la sortie du premier épisode le 9 février 2013, le studio Creative Community est fondé pour supporter le développement de cette série, mais aussi à terme d'autres productions. Quelques mois plus tard, d'autres projets sont lancés en parallèle, parmi lesquels « Noctis ».
                </p>
                <p className="mt-3">
                  Le 11 avril 2013, le studio prend la forme d'une association, jouissant alors d'une existence morale lui permettant de fonctionner de façon structurée, toujours dans l'objectif de développer des productions innovantes et de tisser des partenariats durables.
                </p>
              </div>
              <div className="col-lg-6 col-sm-12" data-aos="fade-up">
                <img src={img1} alt="historique_creative_community" className="img-fluid mt-3"/>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <p className="mt-3">
                  Au fil de son évolution, le studio a su rassembler de nombreux talents. Originellement composée d'une quinzaine de personnes, l'association compte en novembre 2013, après six mois d'existence, plus de trente membres aux compétences et qualifications diverses.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <p className="mt-3">
                  Depuis 2015, l'association organise également des ateliers pédagogiques auprès d'acteurs culturels et éducatifs, pour faire découvrir la technique du machinima et initier à la production audiovisuelle de ce genre. L'association milite, depuis ses débuts, pour la démocratisation du machinima et cherche à rendre le genre accessible au plus grand nombre.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <p className="mt-3">
                  En 2018, Creative Community lance le « 48h CreaCom », premier évènement de création de machinima sur Minecraft le temps d'un weekend en 48 heures. Après de nombreuses éditions, c'est aujourd'hui un évènement qui rassemble de nombreux participants.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <p className="mt-3">
                  Début 2019 marque un renouveau pour Creative Community. L'association décide d'élargir ses activités et ne produit plus uniquement des machinimas sur Minecraft.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <p className="mt-3">
                  Le 11 juillet 2021, Creative Community annonce son changement de nom pour devenir Le Cube à Essai.
                </p>
              </div>
            </div>
          </div>  
          <div className="row justify-content-center">
            <div className="col-12" data-aos="fade-up">
              <h2 className="text-center mt-5 mb-4">Le machinima</h2>
            </div>
          </div>
          <div className="text-justify">
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <p>
                  Le machinima est un genre cinématographique et une technique de production de film né à la fin des années 90. Le terme machinima est un mot-valise, formé à partir de « machine », « cinéma » et « animation ». Cela désigne l’utilisation de séquences vidéo capturées à l’intérieur d’espaces virtuels en temps réel, le plus souvent dans un moteur graphique de jeu vidéo, mais pas exclusivement.
                </p>
              </div>
            </div> 
            <div className="row">
              <div className="col-lg-6 col-sm-12" data-aos="fade-up">
                <img src={img2} alt="machinima" className="img-fluid mt-3"/>
              </div>
              <div className="col-lg-6 col-sm-12" data-aos="fade-up">
                  <p className="mt-3">
                    Le machinima est une philosophie. On parle de gameplay émergent, puisque l’on utilise un jeu vidéo non pas pour jouer, mais pour le détourner, en d’autres termes, pour créer un film. C’est aussi une idée. Chaque personne qui dispose d’un ordinateur et d’un jeu vidéo, peut avec un petit budget (c’est-à-dire le prix de l’ordinateur et du jeu), faire un film. D’autre part, c’est aussi un ensemble de techniques : il existe autant de techniques, que de réalisateurs et de jeux vidéo.
                  </p>
                  <p className="mt-3">
                    Certains jeux vidéo facilitent la création de machinimas, notamment ceux qui permettent de modifier le jeu tel qu’il a été conçu initialement. Les seules limites sont la restriction donnée par le genre du jeu, ses capacités ou son style graphique.
                  </p>
              </div>           
            </div>
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <p className="mt-3">
                  Mais le machinima reste encore peu connu du grand public. Certains spectateurs ne savent pas qu’ils en ont déjà vu, lorsque certains créateurs de machinimas ne savent pas qu’ils viennent d’en créer un. De plus, pour que le machinima devienne accessible au plus grand nombre, il doit se détacher du gameplay du jeu vidéo utilisé afin de créer une symbiose entre jeu vidéo et cinéma. C’est pourquoi dans cette optique, Creative Community s’attache à populariser le genre dans les pays francophones en organisant des ateliers pédagogiques.
                </p>
              </div>
            </div> 
            <div className="text-center" data-aos="fade-up">
              <Link to="/services/ateliers" className="btn btn-dark">En savoir plus sur les ateliers pédagogiques</Link>
            </div>
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <p className="mt-3">
                  Aucun nom officiel admis par tous ne décrit un créateur de machinima, mais on parle généralement de « machinéaste » (machinimist en anglais).
                </p>
              </div>
            </div> 
          </div>
          <div className="row justify-content-center">
            <div className="col-12" data-aos="fade-up">
              <h2 className="text-center mt-5 mb-4">Minecraft et le machinima</h2>
            </div>
          </div>
          <div className="text-justify">
            <div className="row">
              <div className="col-lg-6 col-sm-12" data-aos="fade-up">
                <p className="mt-3">
                  Minecraft est un jeu vidéo de type « bac à sable » aux graphismes cubiques sorti en 2011. Il offre de très grandes libertés de création pour les réalisateurs de machinima. En effet, Minecraft, contrairement à d’autres jeux, permet très facilement de générer ses propres cartes et de construire ses propres décors.
                </p>
                <p className="mt-3">
                  La liberté créative de Minecraft ne s’arrête pas là, il est aussi possible de personnaliser les éléments graphiques du jeu tels que les textures des blocs et des personnages ou ajouter des shaders (pour améliorer le rendu des lumières dans le jeu).
                </p>
              </div>
              <div className="col-lg-6 col-sm-12" data-aos="fade-up">
                <img src={img3} alt="machinima" className="img-fluid mt-3"/>
              </div>
            </div> 
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <p className="mt-3">
                  Mais Minecraft, c’est avant tout sa communauté. Très active depuis 10 ans, le jeu a vendu plus de 176 millions d’exemplaires toute plateformes confondues en mai 2019. L’aspect créatif du jeu a très vite incité les joueurs à partager leur création. Pour en revenir au machinima, plusieurs créateurs ont commencé par des productions assez courtes de quelques minutes, mettant en scène, le plus souvent de façon humoristique, des situations diverses. 
                </p>
              </div>
            </div>     
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <p className="mt-3">
                  Au fil des années, le jeu bénéficie de plusieurs portages sur consoles, smartphones ou tablettes. Mais seule la « Java Edition » (disponible sur Windows, Mac et Linux) a réellement permis aux créateurs d’apporter des modifications au jeu (aussi appelés « mods »). Ainsi, Minecraft s’est vu offrir une grande diversité de nouveau gameplay, blocs, objets, etc.
                </p>
              </div>
            </div> 
           
            <div className="row">
              <div className="col-lg-3 col-sm-12" data-aos="fade-up">
                <img src={img4} alt="McHorse" className="img-fluid mt-3"/>
              </div>
              <div className="col-lg-9 col-sm-12" data-aos="fade-up">
                <p className="mt-3">
                  Minecraft est jouable en multijoueur, ce qui permet la présence de plusieurs acteurs ainsi que plusieurs caméras pour une production. D’autre part, la technique pour réaliser un machinima aussi évolue. De nombreux mods viennent progressivement aider les machinéastes. Aujourd’hui, plusieurs mods s’imposent comme la référence pour produire un machinima sur Minecraft, notamment grâce au créateur McHorse.          
                </p>  
                <p className="mt-3">
                  Le mod Aperture, permet de créer des mouvements de caméras avancés. Blockbuster, permet l’enregistrement de PNJ, rejouer les configurations, ajouter des modèles personnalisés, etc. Bien que difficiles à appréhender, ces deux mods permettent de dépasser les limites imposées par Minecraft. Certains tutoriels vidéo postés par le créateur sont traduits en français par Creative Community.
                </p>   
              </div>        
            </div>
            <div className="text-center mt-3" data-aos="fade-up">
              <a href="https://www.youtube.com/channel/UCSLuDXxxql4EVK_Ktd6PNbw" className="btn btn-dark">Accéder aux Mods Blockbuster et Aperture</a>
            </div>
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <p className="mt-3">
                  Ces dernières années, le machinima Minecraft séduit de plus en plus de créateurs, si bien que le nombre de machinéastes et de communauté se multiplient sur les plateformes de diffusion comme YouTube.
                </p>
              </div>
            </div> 
          </div>
        </div>
      </div>
    );
  }
}
