import React, { Component } from 'react';
import autobind from 'react-autobind';
import ProjectCover from '../../components/projects/ProjectCover';
import {getProjectDetail} from '../../services/Project';
import Credits from '../../components/projects/Credits';
import ProjectModule from '../../components/projects/ProjectModule';
import Reward from '../../components/projects/Reward';
import {getStringDate, initAOS} from '../../services/Common';
import ReactHtmlParser from 'react-html-parser';
import VideoGallery from '../../components/common/VideoGallery';
import Summary from '../../components/projects/Summary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AOS from 'aos';
import 'aos/dist/aos.css';

export default class ProjectPage extends Component {
  constructor(props) {
    super(props);
    const project = getProjectDetail(props.match.params.projectId);
    this.state = {
      id           : project.id,
      name         : project.name,
      release      : project.release,
      nbEpisodes   : project.nbEpisodes,
      description  : project.description,
      genre        : project.genre,
      duration     : project.duration,
      inProduction : project.inProduction,
      type         : project.type,
      videos       : project.videos,
      credits      : project.credits,
      episodes     : project.episodes,
      imdb         : project.imdbId,
      pmc          : project.pmcId,
      rewards      : project.rewards,
      modules      : project.modules,
      tShareMessage: 'Découvrez '+ project.name +', le machinima Minecraft produit et réalisé par @LeCubeAEssai : https://www.creativecommunity.fr/projets/'+project.id,
      fShareMessage: 'Découvrez '+ project.name +', le machinima Minecraft produit et réalisé par Le Cube à Essai : https://www.creativecommunity.fr/projets/'+project.id,
      projectUrl   : 'https://www.creativecommunity.fr/projets/'+project.id
    };
    autobind(this);
  }

  componentDidMount(){
    initAOS(AOS);
  }

  componentWillReceiveProps (){
    AOS.refresh();
  }

  render() {
    const rewards = this.state.rewards.map((e, index) => {
      return <Reward reward={e} key={index}/>;
    });

    const isSerie = this.state.type.indexOf('serie') > -1;
    const is48h = this.state.type.indexOf('48h') > -1;

    const pmcLogo = require('../../assets/images/logos/Planetminecraft_logo.png');

    const creatorsList = () => {
      let creators = [];
      this.state.credits.team.forEach(team => {
        if(team.role.toLowerCase().includes('créateur')){
          creators = team.users;
        }
      });
      return creators.map((realName, index) => {
        return <span key={index}>{realName}{realName === creators[creators.length -1] ? '' : ', '}</span>;
      });
    };

    const directorsList = () => {
      let directors = [];
      this.state.credits.team.forEach(team => {
        if(team.role.toLowerCase().includes('réalisateur')){
          directors = team.users;
        }
      });
      return directors.map((realName, index) => {
        return <span key={index}>{realName}{realName === directors[directors.length -1] ? '' : ', '}</span>;
      });
    };

    const modules = this.state.modules.map((module, index) => {
      return <ProjectModule module={module} key={index}/>;
    });

    let rewardsInfo = '';
    if(this.state.rewards.length > 0){
      rewardsInfo = <div className="w-100" data-aos="fade-up">
        <div className="col-12">
          <p className="h5">Récompenses</p>
        </div>
        <div className="col-12 d-flex justify-content-center flex-wrap">
          {rewards}
        </div>
      </div>;
    }
    let projectGif = '';
    try{
      projectGif = require('../../assets/images/home/'+ this.state.id +'.gif');
    } catch(e) {
      //just to avoid crash
    }

    return (
      <div>
        <Summary modules={this.state.modules} />
        <ProjectCover message={this.state.name} name={this.state.id}/>
        <div>
          <div id="fiche_technique" className="container mb-4">
            <div className="row">
              <div className="col-12">
                <div className="jumbotron pt-4 pb-4" data-aos="fade-up">

                  <p className="h5">Fiche technique {isSerie ? 'de la série' : 'du court-métrage'}</p>
                  <p>
                    <span className="text-muted">Date de sortie </span>
                    {getStringDate(new Date(this.state.release), true, true)}
                  </p>
                  <p>
                    <span className="text-muted">Production </span>
                    {this.state.inProduction ? 'En cours de production' : 'Terminée'}
                  </p>
                  <p>
                    <span className="text-muted">Genre </span>
                    {this.state.genre}
                  </p>
                  {isSerie ? '' : <p><span className="text-muted">Durée </span> {this.state.duration}</p>}
                  {isSerie && this.state.nbEpisodes ? <p><span className="text-muted">Nombre d'épisodes </span> {this.state.nbEpisodes}</p> : ''}
                  <p>
                    <span className="text-muted">Créé par </span>
                    {creatorsList()}
                  </p>
                  <p>
                    <span className="text-muted">Réalisé par </span>
                    {directorsList()}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12" data-aos="fade-up">
                <hr/>
              </div>
            </div>
            <div className="row">
              <div className="col-12" data-aos="fade-up">
                <div className="row">
                  <div className={`col-lg-${projectGif ? '8' : '12'} d-flex flex-column justify-content-center`}>
                    <p className="h5">Résumé</p>
                    <p className="text-justify">
                      {ReactHtmlParser(this.state.description)}
                    </p>
                  </div>
                  {projectGif ?
                    <div className="col-lg-4 text-lg-right text-center">
                      <img className="w-100" src={projectGif} title="Gif du projet" alt="gif du projet"/>
                    </div>
                    : ''}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12" data-aos="fade-up">
                <hr/>
              </div>
            </div>
            <div className="row">
              <div className="col-12" data-aos="fade-up">
                <p className="h5">{isSerie ? 'Voir la bande-annonce et les épisodes' : !is48h ? 'Voir la bande-annonce et le court-métrage' : 'Voir le court-métrage réalisé en 48 heures'}</p>
                {this.state.episodes.length > 0 ? <VideoGallery videos={this.state.episodes} className="mt-4"/> : ''}
              </div>
            </div>
            {this.state.rewards.length > 0 ?
              <div className="row">
                <div className="col-12" data-aos="fade-up">
                  <hr/>
                </div>
              </div>
              : ''
            }
            <div className="row">
              {rewardsInfo}
              <div className="col-12" data-aos="fade-up">
                <hr />
                <p className="h5">Aidez-nous</p>
                <div className="buttons d-flex justify-content-center">
                  {this.state.imdb !== '' ? <a href={'https://www.imdb.com/title/'+ this.state.imdb+'/'} className="btn btn-imdb" title="IMDb"><span>IMDb</span></a> : ''}
                  {this.state.pmc !== '' ? <a href={'https://www.planetminecraft.com/project/' + this.state.pmc + '/'} className="btn btn-pmc ml-3" title="Planet Minecraft"><img src={pmcLogo} alt="logo planetminecraft" /></a> : ''}
                  <a href={'https://twitter.com/intent/tweet?text=' + this.state.tShareMessage} rel="canonical" className="btn btn-twitter ml-3"><span><FontAwesomeIcon icon={['fab', 'twitter']} className="pr-1" size="lg"/>Partager sur Twitter</span></a>
                  <a href={'https://www.facebook.com/sharer/sharer.php?u=' + this.state.projectUrl} className="btn btn-facebook ml-3"><span><FontAwesomeIcon icon={['fab', 'facebook-f']} className="pr-1" size="lg"/>Partager sur Facebook</span></a>
                </div>
              </div>
            </div>
          </div>
          {modules}
          <Credits credits={this.state.credits} />
        </div>
      </div>
    );
  }
}
