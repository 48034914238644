import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import autobind from 'react-autobind';
import Cover from '../../components/cover/Cover';
import {setGridScale, initAOS} from '../../services/Common';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ArticleElementBlank from '../../components/common/ArticleElementBlank';
import ContactFormContainer from '../../components/contact/ContactFormContainer';
import WorkModule from '../../components/common/WorkModule';

import {getDoublagePageDatas, getMoreInformationsDatas, getOurVoicesDatas, getWorkModuleDatas, getCounterpartsDatas} from '../../services/Doublage';

export default class Press extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      datas       : getDoublagePageDatas(),
      moreInfo    : getMoreInformationsDatas(),
      ourVoices   : getOurVoicesDatas(),
      workDoublage: getWorkModuleDatas(),
      counterparts: getCounterpartsDatas()
    };
  }

  componentDidMount(){
    initAOS(AOS); 
  }

  componentWillReceiveProps (){ 
    AOS.refresh(); 
  }

  render() {
    const works = this.state.workDoublage.works.map((work, index) => {
      return (
        <div className={'col-lg-' + setGridScale(this.state.workDoublage.works.length, 6) + ' col-sm-12 mb-4'} data-aos="fade-up" key={index}>
          <WorkModule work={work} index={index} />
        </div>
      );
    });

    const soundcloud = this.state.ourVoices.soundcloud.map((sc, map) => {
      return(
        <div className="col-lg-6 mb-4" key={map}>
          <iframe title={sc.title} width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src={'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/'+sc.id+'&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'}></iframe>
        </div>
      );
    });

    return (
      <div>
        <Cover message={this.state.datas.title} name={this.state.datas.name} />  

        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-12 text-justify">
              {ReactHtmlParser(this.state.datas.description)}
            </div>
          </div>

          <ArticleElementBlank title={this.state.moreInfo.title} description={this.state.moreInfo.description} imagePath={this.state.moreInfo.image} directionToTheLeft={this.state.moreInfo.toTheLeft} color={this.state.moreInfo.color}/>   
       
          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <h2 className="text-center mt-5 mb-4">{this.state.ourVoices.title}</h2>
            </div>

            <div className="col-12 text-justify mb-4">
              {this.state.ourVoices.description}
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="row">
                {soundcloud}
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12" data-aos="fade-up">
              {works.length > 0 ? <h2 className="text-center mt-5 mb-4">{this.state.workDoublage.title}</h2> : ''}
            </div>
            {works}
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <h2 className="text-center mt-5 mb-4">{this.state.counterparts.title}</h2>
            </div>

            <div className="col-12 text-justify mb-4">
              {ReactHtmlParser(this.state.counterparts.description)}
            </div>
          </div>

          {/*<h2 className="text-center mt-5 mb-4" data-aos="fade-up">Parlez-nous de votre projet</h2>
          <ContactFormContainer formName="DoublageForm" requestType="doublage" formColor="red"/>*/}
        </div>

      </div> 
    );
  }
}
