import {getDatas, getSubtitlesModule, getExampleModule, getCounterparts, getWorkModule} from '../api/Translation';

export const getTranslationDatas = () => {
  return getDatas();
};

export const getSubtitlesModuleDatas = () => {
  return getSubtitlesModule();
};

export const getExampleModuleDatas = () => {
  return getExampleModule();
};

export const getCounterpartsDatas = () => {
  return getCounterparts();
};

export const getWorkModuleDatas = () => {
  return getWorkModule();
};