import React from 'react';
import {FormGroup, Label, Input} from 'reactstrap';
import InputForm from './InputForm';

const ContactForm = ({onChange}) => {
  return (
    <div>
      <InputForm name="from_name" labelContent="Votre nom et votre prénom" type="text" required={true} onChange={onChange} />
      <InputForm name="from_email" labelContent="Votre adresse email (pour vous recontacter)" type="email" required={true} onChange={onChange} />
            
      <FormGroup>
        <Label for="requestType">Type de demande <span className="text-danger">*</span></Label>
        <Input type="select" name="requestType" id="requestType" required="true" onChange={onChange}>
          <option value="question">J'ai une question</option>
          <option value="recruitment">Information sur le recrutement</option>
          <option value="partnership">Demande de partenariat</option>
          <option value="other">Autre</option>
        </Input>
      </FormGroup>

      <InputForm name="message" labelContent="Votre message" type="textarea" required={true} onChange={onChange} />
    </div>
  );
};

export default ContactForm;



