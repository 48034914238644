import React from 'react';
import {getGenericString} from '../../services/Common';

const Summary =  ({modules}) => {
  const modulesSummary = modules.map((m, index) => {
    if(m.cover.name){
      return(
        <li key={index}><span onClick={clickOnLink} data-module={getGenericString(m.cover.name)}>{m.cover.name}</span><i></i></li>
      );
    }
    return '';
  });
  return(
    <div className="summary" id="summary" data-aos="fade-left">
      <div className="summary-content">
        <ul>
          <li><span onClick={clickOnLink} data-module="fiche_technique">Fiche technique</span><i></i></li>
          {modulesSummary}
          <li><span onClick={clickOnLink} data-module="credits">Crédits</span><i></i></li>
        </ul>
      </div>
    </div>
  );
};

const clickOnLink = (e) => {
  const element = document.getElementById(e.currentTarget.dataset.module);
  window.scroll({
    behavior: 'smooth',
    left    : 0,
    top     : element.offsetTop - 110
  });
};

export default Summary;