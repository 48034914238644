import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Gallery from './Gallery';
import VideoGallery from './VideoGallery';

const ProjectTextModule = ({module}) => {
  const moduleContent = module.paragraphes.map((p, index) => {
    const hasText = p.textHtml.length > 0;
    const hasImages = p.images.length > 0;
    const hasVideos = p.videos.length > 0;
    const text = hasText ? <div className={(hasImages ? 'col-md-6' : 'col-md-12') + ' d-flex align-items-center m-order-1'}><div className="text-justify w-100">{ReactHtmlParser(p.textHtml)}</div></div> : '';
    const images = hasImages ? <div className={(hasText ? 'col-md-6' : 'col-md-12') + ' d-flex align-items-center m-order-2'}><Gallery imgs={p.images} showThumbnails={true} organized={true} galleryFolder="projects"/></div> : '';
    const videos = hasVideos ? <div className="col-12"><VideoGallery videos={p.videos} className="mt-4"/></div> : '';

    return (
      <div className="row mb-4" data-aos="fade-up" key={index}>
        {p.textOrientation === 'left' ? text : images}
        {p.textOrientation === 'left' ? images : text}
        {videos}
      </div> 
    );
  });
  return (
    <div>
      {moduleContent}
    </div> 
  );
};

export default ProjectTextModule;