import React from 'react';

const ProjectModuleCover =  ({message, imageName, textColor}) => {

  const image = require('../../assets/images/projects/covers/' + imageName + '.jpg');

  return(
    <div className="head project-module" data-aos="fade-up">
      <img src={image} alt="cover" className="img-fluid" />
      <div className="cover-message">
        <h2 className={'text-' + textColor}>{message}</h2>
      </div>
    </div>
  );
};

export default ProjectModuleCover;