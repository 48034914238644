import { getDataFromJson } from './Helper';

export const getDatas = () => {
  return getDataFromJson('recruitment', 'data');
};

export const getQuestions = () => {
  return getDataFromJson('recruitment', 'questions');
};

export const getOneJobById = (id) => {
  const jobs = getJobsResumes();
  return jobs.find(job => job.id === id);
};

export const getCommonMessage = () => {
  return getDataFromJson('recruitment', 'commonMessage');
};

export const getJobsResumes = () => {
  return getDataFromJson('recruitment', 'jobs');
};