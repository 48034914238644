import React, { Component } from 'react';
import autobind from 'react-autobind';
import {getOrderedMembers, getOrderedExternalActors, getOrderedHelpers} from '../../services/Member';
import Member from '../../components/team/Member';
import ExternalActor from '../../components/team/ExternalActor';
import Helper from '../../components/team/Helper';
import Cover from '../../components/cover/Cover';

import {initAOS} from '../../services/Common';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class Team extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount(){
    initAOS(AOS);
  }

  componentWillReceiveProps (){ 
    AOS.refresh(); 
  }

  render() {
    const membersCards = getOrderedMembers().map((member, index) => {
      return(
        <Member member={member} key={index}/>
      );
    });

    const externalActorsCards = getOrderedExternalActors().map((actor, index) => {
      return(
        <ExternalActor actor={actor} key={index}/>
      );
    });

    const helpers = getOrderedHelpers().map((helper, index) => {
      return(
        <Helper helper={helper} key={index}/>
      );
    });
    
    return (
      <div className="position-relative">
        <Cover message="Notre équipe" name="team"/>
        <div className="container large-container position-relative pt-2 pb-2">
          <div className="row justify-content-center">
            {membersCards}
            <Member enrollLink="true"/>
          </div>

          <div>
            <h2 className="text-center mt-5 mb-5" >Les intervenants extérieurs</h2>
            <div className="row justify-content-center">
              {externalActorsCards}
            </div>
          </div>
          
          <div>
            <h2 className="text-center mt-5 mb-5">Ils nous ont aidé <small>({helpers.length})</small></h2>
            <div className="row">
              {helpers}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
