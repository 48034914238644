import React, { Component } from 'react';
import autobind from 'react-autobind';
import {Link} from 'react-router-dom';

export default class PresentationModule extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      title   : props.title,
      url     : props.url,
      titleBtn: props.titleBtn
    };
  }

  render() {

    return (
      <div className="recruitment-module mt-5">
        <div className="recruitment">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-12 text-center">
                <h3>{this.state.title}</h3>
              </div>
              <div className="col-12 text-center mt-3">
                <Link to={this.state.url} className="btn btn-lg btn-dark">{this.state.titleBtn}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}