import React from 'react';
import Video from './Video';

const WorkModule = ({work, index}) => {
  let image = '';
  if(work.image.path){
    image = require('../../assets/images/'+work.image.path);
  }

  let file = '';
  if(work.link.path){
    file = require('../../assets/files/workImages/'+work.link.path);
  }

  return (
    <div className={'card h-100 colored-border bottom ' + work.color} key={index}>
      <div className="card-header">
        <p className="h5">{work.title}</p>
      </div>
      
      {work.video.id ? <Video video={work.video}/> : work.image.path ? <img className="img-fluid" alt={work.image.title} src={image} /> : ''}
      <div className="card-body">
        <p className="card-subtitle mb-2 text-muted">Auteur : <b>{work.author}</b></p>
        {work.director ? <p className="card-subtitle mb-2 text-muted">Directeur d'acteur : <b>{work.director}</b></p> : ''}
        <p className="card-text text-justify">{work.description}</p>      
        {work.link.path ? <a href={file}>Plus de détails</a> : work.link.externalLink ? <a href={work.link.externalLink}>Plus de détails</a> : ''}
        
      </div>
      {work.video.id && work.image.path ? <img className="img-fluid card-img-bottom" alt={work.image.title} src={image} /> : ''}
    </div>
  );
};

export default WorkModule;